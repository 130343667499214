export const environment = {
  production: false,
  apiUrl : 'https://f72p1feuqd.execute-api.ap-southeast-2.amazonaws.com/dev/graphql',
  firebaseConfig: {
    apiKey: "AIzaSyCZdX5boMSUjsEMWLXcAcEYEttLGMWzVqg",
    authDomain: "lpa-energy-group.firebaseapp.com",
    databaseURL: "https://lpa-energy-group-default-rtdb.firebaseio.com",
    projectId: "lpa-energy-group",
    storageBucket: "lpa-energy-group.appspot.com",
    messagingSenderId: "483374438736",
    appId: "1:483374438736:web:a54dcb86589ef2602ab455",
    measurementId: "G-SF2Q85TKXX"
  },
  microapiUrl : 'https://client.api.iconone.dev/clients/lpa/',
  microapiBasicUser : 'lpa-dev',
  microapiBasicPass : '16V(70<v&P*Nz%wS!`+5<]sAE`{Ls@d+lA2T'
};
