<app-loader [showLoader]="isLoading"></app-loader>
<section class="secBgMain1">
  <div class="flexBoxMain">
    <div class="welcomeBackDiv">
      <div class="welcomeFlexBack">
        <h1>
          Welcome
          <span>Back</span>
        </h1>
        <p>
          <!-- Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. -->
        </p>
      </div>
    </div>
    <div class="loginBgBox">
      <img alt="" src="../../assets/images/logo-img.png" />
      <h2>Reset Password</h2>
      <form [formGroup]="loginForm" (ngSubmit)="resetLogin()">
        <div class="pswBox">
          <input type="password" placeholder="New Password" autocomplete="new-password" formControlName="password"
            [type]="showPassword ? 'text' : 'password'" />
          <img [src]="pswEye" (click)="togglePasswordVisibility('new')">
        </div>
        <div class="s_error" *ngIf="
            !loginForm.controls['password'].valid &&
            (loginForm.controls['password'].dirty ||
              loginForm.controls['password'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['password'].hasError('required')">
            New Password is required
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['password'].hasError('minlength')">
            New Password must be at least 8 characters
          </div>
        </div>


        <div class="pswBox">
          <input type="password" placeholder="Confirm Password" autocomplete="new-password" formControlName="confirmPassword"
            [type]="showPasswordConfirm ? 'text' : 'password'" />
          <img [src]="pswEyeConfirm" (click)="togglePasswordVisibility('confirm')">
        </div>
        <div class="s_error" *ngIf="
            !loginForm.controls['confirmPassword'].valid &&
            (loginForm.controls['confirmPassword'].dirty ||
              loginForm.controls['confirmPassword'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['confirmPassword'].hasError('required')">
            Confirm Password is required
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['confirmPassword'].hasError('minlength')">
            Confirm Password must be at least 8 characters
          </div>
        </div>
        <div class="foprgotPasswarrpper">
          <button type="submit" [disabled]="loginForm.invalid">Reset</button>
        </div>
      </form>
    </div>
  </div>
</section>