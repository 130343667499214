import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { LoginComponent } from './login/login.component';
import { MyAuthGuard } from './auth/auth.guard';
import { UsersComponent } from './users/users.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { DatasheetComponent } from './datasheet/datasheet.component';
import { SettingComponent } from './setting/setting.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RefCodeComponent } from './refCode/refCode.component';
import { SymbolMasterComponent } from './symbol-master/symbol-master.component';
import { VerifyComponent } from './verify/verify.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: '', component: PagelayoutComponent, canActivate: [MyAuthGuard], children: [
      { path: 'datasheet', canActivate: [MyAuthGuard], component: ProductdetailsComponent },
      { path: 'all-datasheet', canActivate: [MyAuthGuard], component: DatasheetComponent },
      { path: 'users', canActivate: [MyAuthGuard], component: UsersComponent },
      { path: 'settings', canActivate: [MyAuthGuard], component: SettingComponent },
      { path: 'refCode', canActivate: [MyAuthGuard], component: RefCodeComponent },
      { path: 'master-symbols', canActivate: [MyAuthGuard], component: SymbolMasterComponent }
    ]
  },
  { path: '**', redirectTo: '/datasheet', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
