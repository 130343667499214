<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec">
  <div class="serachMainAllWrapper">
    <div class="searchDiv">
      <form class="example-form">
        <input type="text" placeholder="Users" class="example-input" [(ngModel)]="searchTerm" (input)="serachChange()"
          [ngModelOptions]="{ standalone: true }">

        <!-- <input type="text" placeholder="Users" [formControl]="control" [matAutocomplete]="auto" class="example-input">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
            {{street}}
          </mat-option>
        </mat-autocomplete> -->
      </form>
    </div>
    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="clearForm()">ADD USER</button>
  </div>
  <div class="dataSheetTable">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8">

        <!-- userid Column -->
        <!-- <ng-container matColumnDef="userid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            User ID
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.userid || "___"}} </td>
        </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.name || "___"}} </td>
        </ng-container>

        <!-- position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
            Position Title
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.position || "___"}} </td>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            Email
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.email || "___"}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            Status
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.status === "1" ? "Active" : "InActive" || "___"}}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            Action
          </th>
          <td mat-cell *matCellDef="let element  let i = index">
            <div class="dropMenuMain">
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="element?.action == '0'"
                  (click)="updateUser(dataSource?.data?.[i]?._id, 1)">Active</button>
                <button mat-menu-item *ngIf="element?.action == '1'"
                  (click)="updateUser(dataSource?.data?.[i]?._id, 0)">Inactive</button>
                <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal"
                  (click)="updateUser(dataSource?.data?.[i]?._id, 2)">Edit</button>
              </mat-menu>

              <svg mat-button [matMenuTriggerFor]="menu" xmlns="http://www.w3.org/2000/svg" width="5.991"
                height="20.968" viewBox="0 0 5.991 20.968">
                <defs>
                  <clipPath id="8n8m98hiaa">
                    <path data-name="Rectangle 2150" transform="translate(0 -.001)" style="fill:#002643"
                      d="M0 0h5.991v20.968H0z" />
                  </clipPath>
                </defs>
                <g data-name="Group 1474">
                  <g data-name="Group 1473" style="clip-path:url(#8n8m98hiaa)" transform="translate(0 .001)">
                    <path data-name="Path 1326"
                      d="M0 2.972V.82A.749.749 0 0 1 .813 0h4.375a.748.748 0 0 1 .8.806v4.375a.747.747 0 0 1-.8.808h-4.4A.747.747 0 0 1 0 5.2V2.977"
                      style="fill:#002643" />
                    <path data-name="Path 1327"
                      d="M0 10.472V8.32a.752.752 0 0 1 .824-.831h4.351a.752.752 0 0 1 .814.817v4.352a.751.751 0 0 1-.812.82H.802a.749.749 0 0 1-.8-.807v-2.2"
                      style="fill:#002643" />
                    <path data-name="Path 1328"
                      d="M3.006 14.976h2.152a.756.756 0 0 1 .831.825v4.351a.751.751 0 0 1-.818.814H.82a.753.753 0 0 1-.82-.813v-4.376a.745.745 0 0 1 .806-.8h2.2"
                      style="fill:#002643" />
                  </g>
                </g>
              </svg>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>




      </table>
      <!-- <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator> -->
    </div>



    <div class="noData-msg" *ngIf="!noDataLength">
      <img src="../../assets/images/noDataIcon.png" alt="noData">
    </div>
  </div>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">

          <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>

            <img alt="" src="../../assets/images/close-icon1.png">
          </button>
        </div>
        <div class="modal-body">
          <div class="row rowSpaceMain">
            <form [formGroup]="createUserForm" (ngSubmit)="createUser()">
              <div class="col-sm-12 spaceMainall">
                <h2>{{popUpFunction ? "ADD" : "EDIT"}} USER</h2>
                <div class="formFieldMainAll">
                  <span>User Name</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="User Name" formControlName="username">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['username'].valid &&
            (createUserForm.controls['username'].dirty ||
              createUserForm.controls['username'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['username'].hasError('required')">
                      Username is required
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Email ID</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Email ID" formControlName="email">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['email'].valid &&
            (createUserForm.controls['email'].dirty ||
              createUserForm.controls['email'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['email'].hasError('required')">
                      Email is required
                    </div>
                    <div class="s_validation" *ngIf="createUserForm.controls['email'].hasError('pattern')">
                      Invalid Email Id
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll" *ngIf="popUpFunction">
                  <span>Password</span>
                  <mat-form-field class="example-full-width">
                    <input type="password" matInput placeholder="Password" autocomplete="new-password"
                      formControlName="password">

                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['password'].valid &&
            (createUserForm.controls['password'].dirty ||
              createUserForm.controls['password'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['password'].hasError('required')">
                      Password is required
                    </div>
                    <div class="s_validation" *ngIf="createUserForm.controls['password'].hasError('minlength')">
                      Password must be at least 8 characters
                    </div>
                  </div>
                </div>

                <div class=" formFieldMainAll">
                  <span>Position Title</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Title" formControlName="designation">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['designation'].valid &&
            (createUserForm.controls['designation'].dirty ||
              createUserForm.controls['designation'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['designation'].hasError('required')">
                      Position Title is required
                    </div>
                  </div>
                </div>

                <div class="formFieldMainAll">
                  <span>Role</span>
                  <mat-form-field>
                    <mat-select placeholder="Select" formControlName="role">
                      <mat-option value="">Select</mat-option>
                      <mat-option value="manager">Manager</mat-option>
                      <mat-option value="admin" *ngIf="userType === 'superadmin' ">Admin</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !createUserForm.controls['role'].valid &&
            (createUserForm.controls['role'].dirty ||
              createUserForm.controls['role'].touched ||
              createUserFormSubmit)
          ">
                    <div class="s_validation" *ngIf="createUserForm.controls['role'].hasError('required')">
                      Role is required
                    </div>
                  </div>
                </div>

                <div class="formFieldMainAll">
                  <button class="addUserSend" [disabled]="createUserForm.invalid">{{popUpFunction ? "ADD User AND SEND
                    EMAIL" : "UPDATE"}} </button>
                </div>

              </div>
            </form>

            <form [formGroup]="passwordForm" (ngSubmit)="passwordUpdate()" *ngIf="!popUpFunction">
              <div class="col-sm-12 spaceMainall">
                <h2>CHANGE PASSWORD</h2>
                <div class="formFieldMainAll">
                  <span>New Password</span>
                  <mat-form-field class="example-full-width">
                    <input type="password" matInput placeholder="New Password" autocomplete="new-password"
                      formControlName="newPassword">

                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !passwordForm.controls['newPassword'].valid &&
            (passwordForm.controls['newPassword'].dirty ||
              passwordForm.controls['newPassword'].touched ||
              passwordSubmit)
          ">
                    <div class="s_validation" *ngIf="passwordForm.controls['newPassword'].hasError('required')">
                      New Password is required
                    </div>
                    <div class="s_validation" *ngIf="passwordForm.controls['newPassword'].hasError('minlength')">
                      New Password must be at least 8 characters
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <span>Confirm Password</span>
                  <mat-form-field class="example-full-width">
                    <input type="password" matInput placeholder="Confirm Password" autocomplete="new-password"
                      formControlName="confirmPassword">

                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !passwordForm.controls['confirmPassword'].valid &&
            (passwordForm.controls['confirmPassword'].dirty ||
              passwordForm.controls['confirmPassword'].touched ||
              passwordSubmit)
          ">
                    <div class="s_validation" *ngIf="passwordForm.controls['confirmPassword'].hasError('required')">
                      Confirm Password is required
                    </div>
                    <div class="s_validation" *ngIf="passwordForm.controls['confirmPassword'].hasError('minlength')">
                      Confirm Password must be at least 8 characters
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <button class="addUserSend" [disabled]="passwordForm.invalid"> UPDATE PASSWORD </button>
                </div>
              </div>
            </form>


          </div>
        </div>

      </div>
    </div>
  </div>
</div>