import gql from 'graphql-tag';

export const USERLOGIN = gql`
  mutation userLogin($userEmail: String!, $password: String!) {
    userLogin(userEmail: $userEmail, password: $password) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      token
      resetToken
    }
  }
`;
export const OTP_VERIFY = gql`
  mutation otpVerify($otp: String!, $resetToken: String!) {
    otpVerify(otp: $otp, resetToken: $resetToken) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      hash
      resetToken
      token
      status
    }
  }
`;
export const RESEND_OTP = gql`
  mutation otpResend {
    otpResend {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      hash
      resetToken
      token
      status
    }
  }
`;

export const RESET = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($updateUserInput: UpdateUserInput!) {
    changePassword(updateUserInput: $updateUserInput) {
      message
      status
    }
  }
`;

export const UPDATE_REFCODE = gql`
  mutation updateRefCode(
    $refCode: String!
    $name: String!
    $id: String!
    $type: String!
  ) {
    updateRefCode(refCode: $refCode, name: $name, id: $id, type: $type)
  }
`;
export const ADD_MASTER = gql`
  mutation addMaster($refCode: String!, $name: String!, $type: String!) {
    addMaster(refCode: $refCode, name: $name, type: $type)
  }
`;

export const DELETE_MASTER = gql`
  mutation deleteMaster($typeName: String!, $id: String!) {
    deleteMaster(typeName: $typeName, id: $id)
  }
`;

export const GET_CUSTOM_MASTER = gql`
  query getCustomMaster($typeName: String!) {
    getCustomMaster(typeName: $typeName) {
      _id
      name
      value
      refCode
      others
    }
  }
`;
export const GET_SYMBOLS = gql`
  query getSymbols($typeName: String!) {
    getSymbols(typeName: $typeName) {
      _id
      name
      image
      position
      value
    }
  }
`;

export const MASTER = gql`
  query {
    master {
      _id
      subCategory {
        _id
        name
        value
        refCode
      }
      # vendor {
      #   _id
      #   name
      #   value
      #   refCode
      # }
      subsidiary {
        _id
        name
        value
        refCode
      }
      representativeBrand {
        _id
        name
        value
        refCode
      }
      type {
        _id
        name
        value
        refCode
      }
      chipType {
        _id
        name
        value
        refCode
      }
      dimmingOptions {
        _id
        name
        value
        refCode
      }
      cct {
        _id
        name
        value
        refCode
      }
      cri {
        _id
        name
        value
        refCode
      }
      fittingColor {
        _id
        name
        value
        refCode
      }
      impactRating {
        _id
        name
        value
        refCode
      }
      protectionRating {
        _id
        name
        value
        refCode
      }
      systemLumens {
        _id
        name
        value
        refCode
      }
      symbol {
        name
        image
        value
      }
      accessories {
        _id
        name
        value
        refCode
      }
      powerConnection {
        _id
        name
        value
        refCode
      }
      certifications {
        _id
        name
        value
        refCode
      }
      energyClass {
        _id
        name
        value
        refCode
      }
      macadam {
        _id
        name
        value
        refCode
      }
      warranty {
        _id
        name
        value
        refCode
      }
      classOfProtection {
        _id
        name
        value
        refCode
      }
      reflectorBaffleColour {
        _id
        name
        value
        refCode
      }
      lightSourceChipType {
        _id
        name
        value
        refCode
      }
      opticalSystem {
        _id
        name
        value
        refCode
        others
      }
    }
  }
`;

export const DOWNLOAD_DATA_SHEET = gql`
  mutation downloadDataSheet($id: String!) {
    downloadDataSheet(id: $id) {
      message
      status
      url
    }
  }
`;

export const CREATE_DATA_SHEET = gql`
  mutation createDataSheet($createDataSheetInput: CreateDataSheetInput!) {
    createDataSheet(createDataSheetInput: $createDataSheetInput) {
      _id
      displayName
      sourcePower
      modelNumber
      representativebrand {
        _id
        name
        value
      }
      systemPower
      fittingColour {
        _id
        name
        value
      }
      colourTemp
      beamAngle
      protectionRating {
        _id
        name
        value
      }
      impactRating {
        _id
        name
        value
      }
      ugr
      ledLifeTime
      material
      dimmingOptions {
        _id
        name
        value
      }
      accessoriesImage
      cutOut
      tilt
      rotation
      macAdam {
        _id
        name
        value
      }
      supplyVoltage
      powerConnection {
        _id
        name
        value
      }
      coiCompatibility
      controlGear
      diameter
      height
      length
      class {
        _id
        name
        value
      }
      reflectorType
      reflectorColour {
        _id
        name
        value
      }
      lightSource
      chiptype {
        _id
        name
        value
      }
      warranty {
        _id
        name
        value
      }
      subsidiary {
        _id
        name
        value
      }
      purchasePrice
      vendor {
        _id
        name
        value
      }
      type {
        _id
        name
        value
      }
      size
      version
      series
      # sdcm
      installationMethod
      diffuser
      cct {
        _id
        name
        value
      }
      keyfeatures
      purchaseDesc
      IlluminanceConeDiagram
      OtherRequirements
      salesDesc
      familyName
      typeReference
      subCategory {
        _id
        name
        value
      }
      projectName
      typeNumber
      sourceLumens
      systemLumens
      efficacy
      lOR
      cri {
        _id
        name
        value
      }
      disclaimer
      lpalogo
      ViewProductOnline
      itemCode
      codePurchases
      productName
      productImage
      photometricImage
      inSituphoto
      measurementDiagram
      userId
      userCode
      url
      opticalSystem {
        _id
        name
        value
      }
      certifications {
        _id
        name
        value
      }
      width
      energyClass {
        _id
        name
        value
      }
      driverCode
      operatingTemp
      notes
      symbols
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DATA_SHEET = gql`
  mutation updateDataSheet(
    $nestuiteId: String!
    $updateDataSheetInput: UpdateDataSheetInput!
  ) {
    updateDataSheet(
      nestuiteId: $nestuiteId
      updateDataSheetInput: $updateDataSheetInput
    ) {
      _id
      netSuiteId
      displayName
      sourcePower
      modelNumber
      representativebrand {
        _id
        name
        value
      }
      systemPower
      fittingColour {
        _id
        name
        value
      }
      colourTemp
      beamAngle
      protectionRating {
        _id
        name
        value
      }
      impactRating {
        _id
        name
        value
      }
      ugr
      ledLifeTime
      material
      fittingColor
      dimmingOptions {
        _id
        name
        value
      }
      accessoriesImage
      accessories {
        _id
        name
        value
      }
      cutOut
      tilt
      rotation
      macAdam {
        _id
        name
        value
      }
      supplyVoltage
      powerConnection {
        _id
        name
        value
      }
      coiCompatibility
      controlGear
      diameter
      height
      length
      class {
        _id
        name
        value
      }
      reflectorType
      reflectorColour {
        _id
        name
        value
      }
      lightSource
      chiptype {
        _id
        name
        value
      }
      warranty {
        _id
        name
        value
      }
      subsidiary {
        _id
        name
        value
      }
      purchasePrice
      vendor {
        _id
        name
        value
      }
      type {
        _id
        name
        value
      }
      size
      version
      series
      installationMethod
      diffuser
      cct {
        _id
        name
        value
      }
      keyfeatures
      purchaseDesc
      IlluminanceConeDiagram
      OtherRequirements
      salesDesc
      familyName
      typeReference
      subCategory {
        _id
        name
        value
      }
      projectName
      typeNumber
      sourceLumens
      systemLumens
      efficacy
      lOR
      cri {
        _id
        name
        value
      }
      disclaimer
      lpalogo
      ViewProductOnline
      itemCode
      codePurchases
      productName
      productImage
      photometricImage
      inSituphoto
      measurementDiagram
      userId
      userName
      userCode
      url
      opticalSystem {
        _id
        name
        value
      }
      certifications {
        _id
        name
        value
      }
      width
      energyClass {
        _id
        name
        value
      }
      driverCode
      operatingTemp
      notes
      symbols
      status
    }
  }
`;

export const DATA_SHEETS = gql`
  query dataSheets($listUsersInput: ListDataSheetInput!, $search: String!) {
    dataSheets(listUsersInput: $listUsersInput, search: $search) {
      _id
      itemCode
      representativebrand {
        _id
        name
        value
      }
      url
      userId
      userCode
      userName
      createdAt,
      netSuiteId
    }
  }
`;

export const USER_LIST = gql`
  query users($listUsersInput: ListUsersInput!, $search: String!) {
    users(listUsersInput: $listUsersInput, search: $search) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      status
      subsidiary
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      userName
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      status
    }
  }
`;

export const USER_ONE = gql`
  query user($_id: String!) {
    user(_id: $_id) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      subsidiary
      token
      status
    }
  }
`;

export const USER = gql`
  query user($_id: String!) {
    user(_id: $_id) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      hash
      token
      status
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($updateUserInput: UpdateUserInput!) {
    updateProfile(updateUserInput: $updateUserInput) {
      _id
      code
      userName
      email
      position
      roleType
      picUrl
      hash
      token
      status
    }
  }
`;

export const DATA_SHEET_DETAILS = gql`
  query dataSheetDetails($itemCode: String!) {
    dataSheetDetails(itemCode: $itemCode) {
      message
      status
      data {
        _id
        netSuiteId
        displayName
        sourcePower
        modelNumber
        representativebrand {
          _id
          name
          value
          refCode
        }
        systemPower
        fittingColour {
          _id
          name
          value
          refCode
        }
        colourTemp
        beamAngle
        protectionRating {
          _id
          name
          value
          refCode
        }
        impactRating {
          _id
          name
          value
          refCode
        }
        ugr
        ledLifeTime
        material
        fittingColor
        dimmingOptions {
          _id
          name
          value
          refCode
        }
        accessoriesImage
        accessories {
          _id
          name
          value
          refCode
        }
        cutOut
        tilt
        rotation
        macAdam {
          _id
          name
          value
          refCode
        }
        supplyVoltage
        powerConnection {
          _id
          name
          value
          refCode
        }
        coiCompatibility
        controlGear
        diameter
        height
        length
        class {
          _id
          name
          value
          refCode
        }
        reflectorType
        reflectorColour {
          _id
          name
          value
          refCode
        }
        lightSource
        chiptype {
          _id
          name
          value
          refCode
        }
        warranty {
          _id
          name
          value
          refCode
        }
        subsidiary {
          _id
          name
          value
          refCode
        }
        purchasePrice
        vendor {
          _id
          name
          value
          refCode
        }
        type {
          _id
          name
          value
          refCode
        }
        size
        version
        series
        installationMethod
        diffuser
        cct {
          _id
          name
          value
          refCode
        }
        keyfeatures
        purchaseDesc
        IlluminanceConeDiagram
        OtherRequirements
        salesDesc
        familyName
        typeReference
        subCategory {
          _id
          name
          value
          refCode
        }
        projectName
        typeNumber
        sourceLumens
        systemLumens
        efficacy
        lOR
        cri {
          _id
          name
          value
          refCode
        }
        disclaimer
        lpalogo
        ViewProductOnline
        itemCode
        codePurchases
        productName
        productImage
        photometricImage
        inSituphoto
        measurementDiagram
        userId
        userName
        userCode
        url
        opticalSystem {
          _id
          name
          value
          refCode
        }
        certifications {
          _id
          name
          value
          refCode
        }
        width
        energyClass {
          _id
          name
          value
          refCode
        }
        lightSourceChipType {
          _id
          name
          value
          refCode
        }
        driverCode
        operatingTemp
        notes
        symbols
        productLength
        status
      }
    }
  }
`;

export const SEARCH_DATA_SHEET = gql`
  query searchDataSheet($keyword: String!) {
    searchDataSheet(keyword: $keyword) {
      message
      status
      data {
        id
        itemid
      }
    }
  }
`;

export const ADD_MASTER_SYMBOL = gql`
  mutation addMasterSymbol(
    $value: String!
    $image: String!
    $position: String!
    $name: String!
  ) {
    addMasterSymbol(
      value: $value
      image: $image
      position: $position
      name: $name
    )
  }
`;
export const UPDATE_MASTER_SYMBOL = gql`
  mutation updateSymbol(
    $value: String!
    $image: String!
    $position: String!
    $name: String!
    $id: String!
  ) {
    updateSymbol(
      value: $value
      image: $image
      position: $position
      name: $name
      id: $id
    )
  }
`;
export const deleteMaster = gql`
  mutation addMasterSymbol(
    $value: String!
    $image: String!
    $position: String!
    $name: String!
  ) {
    addMasterSymbol(
      value: $value
      image: $image
      position: $position
      name: $name
    )
  }
`;
