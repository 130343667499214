import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ViewChild } from '@angular/core';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GraphqlService } from '../graphql.service';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { 
  CREATE_USER, 
  UPDATE_USER, 
  USER_LIST, 
  USER_ONE, 
  MASTER 
} from '../helper/queries';
import { helperService } from '../helper/helper';

const USER_DROPDOWN_TYPE_INACTIVE = 0;
const USER_DROPDOWN_TYPE_ACTIVE = 1;
const USER_DROPDOWN_TYPE_EDIT = 2;

let ELEMENT_DATA: any = [];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  createUserForm: FormGroup
  createUserFormSubmit: boolean;
  selected = ""
  control = new FormControl('');
  streets: string[] = [];
  filteredStreets?: Observable<string[]>;
  @ViewChild('closeModal') closeModal: any;
  isLoading: boolean = true;
  searchTerm: string;
  searchKeyword: string = ''
  popUpFunction: boolean = true
  passwordForm: FormGroup;
  passwordSubmit: boolean;
  noDataLength: number
  userType: String;
  displayedColumns: string[] = ['name', 'position', 'email', 'role', 'subsidiary', 'status', 'action'];
  dataSource: any;
  dataSourceUsers : any = null;
  dataSourceSubsidiary : any = null;

  //Subsidiary properties
  listvalue: any;
  @ViewChild('selectSubsidiary') select: MatSelect;
  allSelectedSubsidiary = false;
  dropDownValues : any = {
    subsidiary: null,
  }


  ngOnInit() {
    // this.filteredStreets = this.control.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || '')),
    // );
    this.createUserForm = this._formBuilder.group({
      _id: [''],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      designation: ['', [Validators.required]],
      role: ['', [Validators.required]],
      subsidiary: ['', [Validators.required]],
    });

    this.passwordForm = this._formBuilder.group({
      _id: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });

    //Populate subsidiary dropdown
    const subsidiaryControl = this.createUserForm.get('subsidiary');
    if (subsidiaryControl) {
      subsidiaryControl.valueChanges.subscribe((selectedValues: string[]) => {

        const subsidiaryValues = this.listvalue?.subsidiary ? this.listvalue?.subsidiary.sort((a: any, b : any) => a.name.trim() > b.name.trim() ? 1 : -1) : [];

        let output = this._helper.convertArrayObject(
          subsidiaryValues,
          selectedValues || []
        );
        this.dropDownValues.subsidiary = output;
      });
    }

  }

  // private _filter(value: string): string[] {
  //   const filterValue = this._normalizeValue(value);
  //   return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  // }

  // private _normalizeValue(value: string): string {
  //   return value.toLowerCase().replace(/\s/g, '');
  // }

  

  constructor(
    private _liveAnnouncer: LiveAnnouncer, 
    private _formBuilder: FormBuilder, 
    private graphqlService: GraphqlService, 
    private toastr: ToastrService,
    private _helper: helperService
  ) {
    this.userlist()
    this.getUserDetails()
    this.masterData()
  }

  toggleAllSelectionSubsidiary() {
    if (this.allSelectedSubsidiary) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  @ViewChild(MatSort)
  sort!: MatSort;

  clearForm() {
    this.popUpFunction = true
    this.createUserForm.controls['password'].setValidators([Validators.required, Validators.minLength(8)])
    this.createUserForm.controls['password'].updateValueAndValidity();
    this.createUserForm.reset();
  }

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  // }

  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getUserDetails(): void {
    const detailsString = localStorage.getItem("details")
    if (detailsString) {
      const userDetails = JSON.parse(detailsString);
      this.userType = userDetails?.roleType?.trim().toLowerCase()
    }
  }

  serachChange(): void {
    this.searchKeyword = this.searchTerm
    this.userlist()
  }

  

  userlist(): void {
    this.graphqlService.client
      .query({
        query: USER_LIST,
        variables: { listUsersInput: { limit: 1000, offset: 0 }, search: this.searchKeyword },
        fetchPolicy: 'network-only',
      })
      .then((result: any) => {
        this.noDataLength = (result?.data?.users?.length)
        const user: any = result?.data?.users?.map((resp: any) => ({
          _id: resp?._id,
          userid: resp?.code,
          name: resp?.userName,
          email: resp?.email,
          position: resp?.position,
          status: resp?.status,
          subsidiary : resp?.subsidiary || [],
          role : resp?.roleType || null,
          action: resp?.status,
        }));

        this.dataSourceUsers = user;

        //Map user subsidiary data with master data
        this.parseUserLisrData();
        
        // this.dataSource = new MatTableDataSource(user);
        // this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error);
      });
  }


  masterData () : void {

    this.graphqlService.client
      .query({
        query: MASTER,
        fetchPolicy: 'no-cache',
      })
      .then(({ data, loading }) => {
        this.listvalue = data?.master || {};
        this.dataSourceSubsidiary = data?.master?.subsidiary || [];
        // this.isLoading = loading;
        this.parseUserLisrData();
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error);
      });

  }


  //Map user subsidiary data with master data
  parseUserLisrData () : void {
    
    if(this.dataSourceUsers && this.dataSourceSubsidiary) {

      let user : any = [];

      //Add the subsidiary names to the user
      this.dataSourceUsers.forEach((dataSourceUser : any) => {

        let listUser = {...dataSourceUser};
        
        let userSubsidiaries : string[] = [];

        if(dataSourceUser.subsidiary?.length === this.dataSourceSubsidiary.length) {
          userSubsidiaries.push('LPA Group (all)');
        } else {
          dataSourceUser.subsidiary?.forEach((subsidiary : any) => {
            let dataSub = this.dataSourceSubsidiary.find((s : any) => s.value === subsidiary)
            if(dataSub) {
              userSubsidiaries.push(dataSub.name)
            }
          });

        }

        listUser.userSubsidiaries = userSubsidiaries;

        user.push(listUser)
      });

      this.dataSource = new MatTableDataSource(user);
      this.isLoading = false;

    }

  }


  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = false;
  disabled = false;

  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30, 40, 50];

  pageEvent: PageEvent;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  //Update user details
  saveUser(): void {
    if (this.createUserForm.valid) {
      const value = this.createUserForm.value;
      // console.log('saveUser()', value)
      
      if (value?._id) {
        this.graphqlService.client
          .mutate({
            mutation: UPDATE_USER,
            variables: { 
              updateUserInput: { 
                id: value?._id, 
                userName: value?.username, 
                email: value?.email, 
                position: value?.designation, 
                roleType: value?.role, 
                subsidiary : value?.subsidiary 
              }
            },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.userlist()
            this.toastr.success('User Updated Successfully');
            this.createUserForm.reset();
            this.closeModal.nativeElement.click();
          })
          .catch((error: any) => {
            this.toastr.error(error.message);
            console.error(error);
          });
      } else {
        this.graphqlService.client
          .mutate({
            mutation: CREATE_USER,
            variables: {
              createUserInput: { 
                userName: value?.username, 
                email: value?.email, 
                password: value?.password, 
                position: value?.designation, 
                roleType: value?.role, 
                subsidiary : value?.subsidiary 
              } 
            },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.userlist()
            this.toastr.success('User Added Successfully');
            this.createUserForm.reset();
            this.closeModal.nativeElement.click();
          })
          .catch((error: any) => {
            this.toastr.error(error.message);
            console.error(error);
          });
      }



    }
  }

  //Update user status / trigger modal
  updateUser(id: any, type: number): void {

    if (type === USER_DROPDOWN_TYPE_EDIT && id) {
      //Edit / popup
      this.popUpFunction = false
      this.isLoading = true;
      this.createUserForm.controls['password'].clearValidators()
      this.createUserForm.controls['password'].updateValueAndValidity();
      this.graphqlService.client
        .query({
          query: USER_ONE,
          variables: { _id: id },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const userDetails = data?.user;
          const newData = {
            _id: userDetails?._id,
            username: userDetails?.userName,
            email: userDetails?.email,
            designation: userDetails?.position,
            role: userDetails?.roleType,
            subsidiary : userDetails?.subsidiary || []
          };
          
          this.createUserForm.patchValue(newData);
          this.passwordForm.patchValue({ _id: userDetails?._id });
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.error(error);
        });
    } else if (id && (type === USER_DROPDOWN_TYPE_ACTIVE || type === USER_DROPDOWN_TYPE_INACTIVE)) {
      //Active / inactive toogle
      this.graphqlService.client
        .mutate({
          mutation: UPDATE_USER,
          variables: { updateUserInput: { id: id, status: type?.toString(), } },
        })
        .then(() => {
          this.userlist()
          this.toastr.success('Status Updated Successfully');
          this.createUserForm.reset();
          this.closeModal.nativeElement.click();
        })
        .catch((error: any) => {
          this.toastr.error('Something Went Wrong... Try Again');
          console.error(error);
        });

    }
  }

  //Update user password
  passwordUpdate(): void {
    if (this.passwordForm.valid) {
      const value = this.passwordForm.value;
      if (value?.newPassword !== value?.confirmPassword) {
        this.toastr.error('Passwords must match...');
      } else {
        this.isLoading = true;
        this.graphqlService.client
          .mutate({
            mutation: UPDATE_USER,
            variables: { updateUserInput: { id: value?._id, password: value?.newPassword } },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.toastr.success('Password Updated Successfully');
            this.passwordForm.reset();
            this.isLoading = false;
            this.closeModal.nativeElement.click();
          })
          .catch((error: any) => {
            this.toastr.error('Something Went Wrong... Try Again');
            this.isLoading = false;
            console.error(error);
          });
      }

    }

  }

}
