import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { helperService } from '../helper/helper';
import { ToastrService } from 'ngx-toastr';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';

type MasterOption = {
  master : string;
  label : string;
}

@Component({
  selector : 'app-mastersync',
  templateUrl : './mastersync.component.html',
  styleUrls : ['./mastersync.component.scss'],
})
export class MastersyncComponent implements OnInit {
  
  isLoading: boolean = false;
  validForm : boolean|null = null;
  userDetails: any = null;
  masterForm: FormGroup;
  masterFormSubmit: FormGroup;

  masterOptions : MasterOption[] = [
    {
      master : 'opticalSystem',
      label : 'Optical System'
    },
    {
      master : 'fittingColor',
      label : 'Fitting Colour'
    },
    {
      master : 'subCategory',
      label : 'Sub Category'
    },
    {
      master : 'symbol',
      label : 'Symbol'
    },
    {
      master : 'supplier',
      label : 'Supplier'
    },
    {
      master : 'sourceLumens',
      label : 'Source Lumens'
    },
    {
      master : 'systemPower',
      label : 'System Power'
    },
    {
      master : 'colourTemp',
      label : 'Colour Temperature'
    },
    {
      master : 'protectionRating',
      label : 'Protection Rating'
    },
    {
      master : 'impactRating',
      label : 'Impact Rating'
    },
    {
      master : 'fittingColor',
      label : 'Fitting Colour'
    },
    {
      master : 'cri',
      label : 'CRI'
    },
    {
      master : 'lightSourceChipType',
      label : 'Light Source Type'
    },
    {
      master : 'cct',
      label : 'CCT'
    },
    {
      master : 'dimmingOptions',
      label : 'Dimming Options'
    },
    {
      master : 'chipType',
      label : 'Chip Type'
    },
    {
      master : 'type',
      label : 'Type'
    },
    {
      master : 'subsidiary',
      label : 'Subsidiary'
    },
    {
      master : 'vendor',
      label : 'Vendor'
    },
    {
      master : 'systemLumens',
      label : 'System Lumens'
    },
    {
      master : 'accessories',
      label : 'Accessories'
    },
    {
      master : 'powerConnection',
      label : 'Power Connection'
    },
    {
      master : 'certifications',
      label : 'Certifications'
    },
    {
      master : 'energyClass',
      label : 'Energy Class'
    },
    {
      master : 'mcadam',
      label : 'Mcadam'
    },
    {
      master : 'warranty',
      label : 'Warranty'
    },
    {
      master : 'classOfProtection',
      label : 'Class of Protection'
    },
    {
      master : 'reflectorBaffleColour',
      label : 'Reflector Baffle Colour'
    },
    {
      master : 'opticalSystem',
      label : 'Optical System'
    },
    {
      master : 'representativeBrand',
      label : 'Representative Brand'
    },
  ];

  masterOptionsSorted : MasterOption[] = [];

  dropDownValues : any = {
    master : null
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _helper: helperService,
    private toastr: ToastrService,
  ) {

  }

  ngOnInit() {

    this.userDetails = this._helper.getUser();

    this.masterOptionsSorted = this.masterOptions.sort((a : any, b : any) => a.label < b.label ? -1 : 1)

    this.masterForm = this._formBuilder.group({
      master : ['', [Validators.required]]
    });

    const masterControl = this.masterForm.get('master');
    if (masterControl) {
      masterControl.valueChanges.subscribe((selectedValue) => {
        // console.log('selectedValue', selectedValue);
        this.dropDownValues.type = selectedValue;
      });
    }

  }

  syncNetsuite() : void {
    // console.log('Initiate a background Netsuite Sync')
    const self = this;

    this.isLoading = true;
    this.validForm = null;

    const master = this.dropDownValues.type || null;
    const email = this.userDetails.email || null;

    if(master) {

      this.validForm = true;

      fetch(
        `${environment?.microapiUrl}netsuite/userevent`,
        {
          method : 'POST',
          headers : {
            'Authorization' : 'Basic ' + btoa(environment?.microapiBasicUser + ":" + environment?.microapiBasicPass),
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify({ master, email })
        }
      ).then(resp => {
        if(resp.ok && resp.status < 300) {
          this.dropDownValues.type = null;
          this.toastr.success('Netsuite data sync request has been sent...');
        } else {
          console.log('resp', resp);
          this.toastr.error('Could not send data sync request... Please Try Again');
        }
      }).catch(err => {
        console.error('err', err);
        this.toastr.error('Could not send data sync request... Please Try Again');
      }).finally(() => {
        self.isLoading = false;
      })

    } else {
      this.validForm = false;
      this.isLoading = false;
    }

  }


}