import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GraphqlService } from '../graphql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OTP_VERIFY, RESEND_OTP } from '../helper/queries';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class VerifyComponent {
  resetToken!: string;

  isLoading: boolean = false;

  otpLength: number = 6;

  config = {
    allowNumbersOnly: true,
    length: this.otpLength,
    inputStyles: {
      width: '50px',
      height: '50px',
      fontSize: '16px',
    },
  };

  constructor(
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.autoLogin();

    this.route.queryParams.subscribe((params) => {
      this.resetToken = params['token'];
      const UUID = params['uuid'];
      localStorage.setItem('uuid', UUID);
    });

    // console.log(this.UUID);
  }

  autoLogin(): void {
    const userId = localStorage.getItem('uuid');
    const token = localStorage.getItem('token');
    if (userId && token) {
      this.router.navigate(['/datasheet']);
    }
  }

  onOtpChange(event: any): void {
    if (event?.length === this.otpLength) {
      this.otpVerify(event);
    }
  }

  otpVerify(otp: number): void {
    this.graphqlService.client
      .mutate({
        mutation: OTP_VERIFY,
        variables: { otp: otp, resetToken: this.resetToken },
        fetchPolicy: 'network-only',
      })
      .then((result: any) => {
        // console.log(result);
        const userId = result?.data?.otpVerify?._id;
        const token = result?.data?.otpVerify?.token;
        const data = result?.data?.otpVerify;
        localStorage.setItem('uuid', userId);
        localStorage.setItem('token', token);
        localStorage.setItem('details', JSON.stringify(data));
        this.router.navigate(['/datasheet']);
      })
      .catch((error: any) => {
        this.isLoading = false;
        this.toastr.error(error?.message);
        console.error(error);
      });
  }

  resendOTP(): void {
    this.graphqlService.client
      .mutate({
        mutation: RESEND_OTP,
        fetchPolicy: 'network-only',
      })
      .then((result: any) => {
        this.resetToken = result?.data?.otpResend?.resetToken;
        this.toastr.success('OTP has been resent successfully to your email');
      })
      .catch((error: any) => {
        this.isLoading = false;
        this.toastr.error('SOMETHING WENT WRONG. Please try again later.');
        console.error(error);
      });
  }
}
