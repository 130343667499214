import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs';
import { getStorage, ref } from 'firebase/storage';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadServiceService {
  constructor(private storage: AngularFireStorage) {}

  uploadImage(file: File): Promise<string> {
    const fileName = file.name ?? 'accessories-' + Math.random();
    const filePath = `Accessories/${fileName}`;
    const fileRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);

    return new Promise((resolve, reject) => {
      uploadTask
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(
              (downloadURL) => {
                resolve(downloadURL);
              },
              (error) => {
                reject(error);
              }
            );
          })
        )
        .subscribe();
    });
  }

  getFileName(url: string): string | undefined {
    const storage = getStorage();
    const httpsReference = ref(storage, url);
    const firebaseName = httpsReference?.name;
    if (firebaseName) {
      return firebaseName;
    }
    return undefined;
  }
}
