import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService } from '../graphql.service';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { UPDATE_PROFILE, USER } from '../helper/queries';
import { ImageUploadServiceService } from '../image-upload-service.service';
import { helperService } from '../helper/helper';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent {
  selected = '';
  settingForm: FormGroup;
  settingSubmit: boolean;
  isLoading: boolean = false;
  passwordForm: FormGroup;
  passwordSubmit: boolean;
  picUrl: string = '../../assets/images/profile-avatar.png';

  constructor(
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private toastr: ToastrService,
    private imageUploadService: ImageUploadServiceService,
    private _helper: helperService
  ) {}

  onFileSelected(event: any) {
    const fileObj = event.target.files[0];
    this.imageUploadService
      .uploadImage(fileObj)
      .then((downloadURL) => {
        this.picUrl = downloadURL;
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  }

  ngOnInit(): void {
    this.settingForm = this._formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      // role: ['', [Validators.required]],
      designation: ['', [Validators.required]],
    });
    this.passwordForm = this._formBuilder.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });

    const UUID = localStorage.getItem('uuid');
    if (UUID) {
      this.isLoading = true;
      this.graphqlService.client
        .query({
          query: USER,
          variables: { _id: UUID },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const userDetails = data?.user;
          const newData = {
            _id: userDetails?._id,
            name: userDetails?.userName,
            email: userDetails?.email,
            role: userDetails?.roleType,
            designation: userDetails?.position,
          };
          if (userDetails?.picUrl) {
            this.picUrl = userDetails?.picUrl;
          }
          this.settingForm.patchValue(newData);
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.error(error);
        });
    }
  }

  userUpdate(): void {
    if (this.settingForm.valid) {
      this.isLoading = true;
      const value = this.settingForm.value;
      if (value?._id) {
        this.graphqlService.client
          .mutate({
            mutation: UPDATE_PROFILE,
            variables: {
              updateUserInput: {
                id: value?._id,
                userName: value?.name,
                position: value?.designation,
                roleType: value?.role,
                picUrl: this.picUrl,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            const detailsString = localStorage.getItem('details');
            if (detailsString) {
              const userDetails = JSON.parse(detailsString);
              userDetails.userName = res?.data?.updateProfile?.userName;
              userDetails.roleType = res?.data?.updateProfile?.roleType;
              userDetails.picUrl = res?.data?.updateProfile?.picUrl;
              localStorage.setItem('details', JSON.stringify(userDetails));
            }
            this.toastr.success('Profile Updated Successfully');
            this._helper.getUser();
            this._helper.getUserPicUrl();
            this.isLoading = false;
          })
          .catch((error: any) => {
            this.toastr.error('Something Went Wrong... Try Again');
            this.isLoading = false;
            console.error(error);
          });
      } else {
        this.isLoading = false;
        this.toastr.error('Something Went Wrong... Try Again');
      }
    }
  }

  passwordUpdate(): void {
    if (this.passwordForm.valid) {
      const value = this.passwordForm.value;
      if (value?.newPassword !== value?.confirmPassword) {
        this.toastr.error('Passwords must match...');
      } else {
        this.isLoading = true;
        const UUID = localStorage.getItem('uuid');
        this.graphqlService.client
          .mutate({
            mutation: UPDATE_PROFILE,
            variables: {
              updateUserInput: {
                id: UUID,
                currentPassword: value?.currentPassword,
                password: value?.newPassword,
              },
            },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.toastr.success('Password Updated Successfully');
            this.passwordForm.reset();
            this.isLoading = false;
          })
          .catch((error: any) => {
            this.toastr.error('Current password is wrong... Try Again');
            this.isLoading = false;
            console.error(error);
          });
      }
    }
  }
}
