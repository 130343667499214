import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService } from '../graphql.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { USERLOGIN } from '../helper/queries';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  loginSubmit: boolean;
  isLoading: boolean = false;
  password: string;
  showPassword: boolean = false;
  pswEye: string = '../../assets/images/password-eye-close.png';

  constructor(
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.autoLogin();
  }

  autoLogin(): void {
    const userId = localStorage.getItem('uuid');
    const token = localStorage.getItem('token');
    if (userId && token) {
      this.router.navigate(['/datasheet']);
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.pswEye = '../../assets/images/password-eye.png';
    } else {
      this.pswEye = '../../assets/images/password-eye-close.png';
    }
  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  loginUser(): void {
    localStorage.clear();
    if (this.loginForm.valid) {
      this.isLoading = true;
      const value = this.loginForm.value;
      this.graphqlService.client
        .mutate({
          mutation: USERLOGIN,
          variables: { userEmail: value?.email, password: value?.password },
          fetchPolicy: 'network-only',
        })
        .then((result: any) => {
          // const userId = result?.data?.userLogin?._id;
          // const token = result?.data?.userLogin?.token;
          // const data = result?.data?.userLogin
          // localStorage.setItem('uuid', userId);
          // localStorage.setItem('token', token);
          // localStorage.setItem('details', JSON.stringify(data));
          // this.router.navigate(['/datasheet']);
          const resetToken = result?.data?.userLogin?.resetToken;
          this.router.navigate(['/verify'], {
            queryParams: {
              token: resetToken,
              uuid: result?.data?.userLogin?._id,
            },
            queryParamsHandling: 'merge',
          });
        })
        .catch((error: any) => {
          this.isLoading = false;
          this.toastr.error('Invalid Email Id or Password');
          console.error(error);
        });
    }
  }
}
