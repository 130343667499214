import { Injectable } from '@angular/core';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client/link/core';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {

  public client: ApolloClient<any>;

  constructor(httpLink: HttpLink) {
    const link = httpLink.create({ uri: environment?.apiUrl });

    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem('token');
      const UUID = localStorage.getItem('uuid');
      return {
        headers: {
          ...headers,
          uuid: UUID ? UUID : '',
          Authorization: token ? `Bearer ${token}` : '',
          "Access-Control-Allow-Origin": "*",
          "Cross-Origin-Embedder-Policy": "require-corp",
          "Cross-Origin-Opener-Policy": "same-origin",
        }
      };
    });

    this.client = new ApolloClient({
      link: ApolloLink.from([authLink, link]),
      cache: new InMemoryCache(),
    });
  }
}
