<app-loader [showLoader]="isLoading"></app-loader>
<section class="secBgMain1">
    <div class="flexBoxMain">
        <div class="welcomeBackDiv">
            <div class="welcomeFlexBack">
                <h1>
                    Welcome Back
                    <!-- <span></span> -->
                </h1>
                <p>
                    <!-- Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. -->
                </p>
            </div>
        </div>
        <div class="loginBgBox">
            <img alt="" src="../../assets/images/logo-img.png" />
            <h2>OTP</h2>
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="verifyWrapper">
                <div class="verify-innner">
                    <p (click)="resendOTP()">Resend?</p>
                    <a routerLink="login">login</a>
                </div>
            </div>
        </div>
    </div>
</section>