<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec">
    <div class="serachMainAllWrapper">
        <div class="searchDiv">
            <form class="example-form">
                <input type="text" placeholder="Symbols" class="example-input" [(ngModel)]="searchTerm"
                    (input)="serachChange()" [ngModelOptions]="{ standalone: true }">
            </form>
        </div>
        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">ADD
            Symbols</button>
    </div>
    <div class="dataSheetTable">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
                class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.name || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                        Value
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.value || "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                        Image
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="openImage(element?.imageUrl)"> {{element?.image ||
                        "___"}} </td>
                </ng-container>
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                        Position
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element?.position || "___"}} </td>
                </ng-container>


                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element  let i = index">
                        <div class="dropMenuMain">
                            <mat-menu #menu="matMenu">

                                <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    (click)="fetchData(element)">Edit</button>
                                <button mat-menu-item (click)="deleteMaster(element?._id)">Delete</button>
                            </mat-menu>

                            <svg mat-button [matMenuTriggerFor]="menu" xmlns="http://www.w3.org/2000/svg" width="5.991"
                                height="20.968" viewBox="0 0 5.991 20.968">
                                <defs>
                                    <clipPath id="8n8m98hiaa">
                                        <path data-name="Rectangle 2150" transform="translate(0 -.001)"
                                            style="fill:#002643" d="M0 0h5.991v20.968H0z" />
                                    </clipPath>
                                </defs>
                                <g data-name="Group 1474">
                                    <g data-name="Group 1473" style="clip-path:url(#8n8m98hiaa)"
                                        transform="translate(0 .001)">
                                        <path data-name="Path 1326"
                                            d="M0 2.972V.82A.749.749 0 0 1 .813 0h4.375a.748.748 0 0 1 .8.806v4.375a.747.747 0 0 1-.8.808h-4.4A.747.747 0 0 1 0 5.2V2.977"
                                            style="fill:#002643" />
                                        <path data-name="Path 1327"
                                            d="M0 10.472V8.32a.752.752 0 0 1 .824-.831h4.351a.752.752 0 0 1 .814.817v4.352a.751.751 0 0 1-.812.82H.802a.749.749 0 0 1-.8-.807v-2.2"
                                            style="fill:#002643" />
                                        <path data-name="Path 1328"
                                            d="M3.006 14.976h2.152a.756.756 0 0 1 .831.825v4.351a.751.751 0 0 1-.818.814H.82a.753.753 0 0 1-.82-.813v-4.376a.745.745 0 0 1 .806-.8h2.2"
                                            style="fill:#002643" />
                                    </g>
                                </g>
                            </svg>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>



        <div class="noData-msg" *ngIf="!noDataLength">
            <img src="../../assets/images/noDataIcon.png" alt="noData">
        </div>
    </div>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">

                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>

                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row rowSpaceMain">
                        <form [formGroup]="passwordForm" (ngSubmit)="createSymbol()">
                            <div class="col-sm-12 spaceMainall">
                                <h2>Create Symbol</h2>
                                <div class="formFieldMainAll">
                                    <span>User Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="name" formControlName="name">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>value</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="value" formControlName="value">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>position</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="position" formControlName="position">
                                    </mat-form-field>
                                </div>
                                <div class="formFieldMainAll">
                                    <span>image</span>
                                    <input type="file" placeholder="image" (change)="onFileSelected($event)">
                                </div>


                                <div class="formFieldMainAll">
                                    <button type="submit" class="addUserSend"
                                        [disabled]="passwordForm.invalid">SUBMIT</button>
                                </div>

                            </div>
                        </form>




                    </div>
                </div>

            </div>
        </div>
    </div>
</div>