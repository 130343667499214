<div class="payLayOutSecMain">
  <app-header></app-header>
  <div class="payLayOutSecMainPos">
    <app-sidebar></app-sidebar>
  </div>

  <div class="sideBarMenuMobile">
    <button class="btn btn-primary menuMobile" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
      <img alt="" src="../../assets/images/mobile-menu.png">
    </button>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">

        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">

        </button>
      </div>
      <div class="offcanvas-body">
        <div class="sideBarMain">
          <img class="logoImg" alt="" src="../../assets/images/logo-img.png">
          <ul>
            <li> <a routerLink="/datasheet" routerLinkActive="activeMain"><span>
                  <img src="../../assets/images/list-icon.png"></span>Datasheet </a> </li>
            <li> <a routerLink="/all-datasheet" routerLinkActive="activeMain"><span>
                  <img src="../../assets/images/list-icon.png"></span>All Datasheet </a> </li>
            <li *ngIf="userType === 'admin' ||  userType === 'superadmin' "> <a routerLink="/users"
                routerLinkActive="activeMain"><span>
                  <img src="../../assets/images/profile-icon.png"></span>Users </a> </li>
            <!-- <li> <a routerLink="/settings" routerLinkActive="activeMain"><span>
                  <img src="../../assets/images/settings-icons.png"></span>Settings </a> </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

</div>