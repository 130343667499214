import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { helperService } from '../helper/helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  userDetails: any = null;
  picUrl: String = '../../assets/images/profile-avatar.png';
  @Input() headerValue: String = `Welcome To LPA`;

  constructor(private router: Router, private _helper: helperService) {
    // this.getUserDetails();
  }

  ngOnInit(): void {
    this.userDetails = this._helper.getUser();
    this.picUrl = this._helper.getUserPicUrl();
  }

  // getUserDetails(): void {
  //   const detailsString = localStorage.getItem("details")
  //   if (detailsString) {
  //     this.userDetails = JSON.parse(detailsString);
  //     if (this.userDetails?.picUrl) {
  //       this.picUrl = this.userDetails?.picUrl
  //     }
  //   }
  // }

  setHeader(isSelect: String): void {
    this.headerValue = isSelect;
  }

  logoutUser(): void {
    try {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    } catch (err) {
      console.log('Logout Failed', { err });
    }
  }
}
