import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { DatasheetComponent } from './datasheet/datasheet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http'
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { MatSelectModule } from '@angular/material/select';
import { UsersComponent } from './users/users.component';
import { MatMenuModule } from '@angular/material/menu';
import { SettingComponent } from './setting/setting.component';
import { ToastrModule } from 'ngx-toastr';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularFireModule } from "@angular/fire/compat";
import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RefCodeComponent } from './refCode/refCode.component';
import { SymbolMasterComponent } from './symbol-master/symbol-master.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VerifyComponent } from './verify/verify.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { MastersyncComponent } from './mastersync/mastersync.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    PagelayoutComponent,
    DatasheetComponent,
    ProductdetailsComponent,
    UsersComponent,
    MastersyncComponent,
    SettingComponent,
    LoaderComponent,
    ForgotpasswordComponent,
    ChangePasswordComponent,
    RefCodeComponent,
    SymbolMasterComponent,
    VerifyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatMenuModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    MatPaginatorModule,
    MatProgressSpinnerModule,
    ImageCropperModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    MatCheckboxModule,
    NgSelectModule,
    NgOtpInputModule


  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
