import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ViewChild } from '@angular/core';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GraphqlService } from '../graphql.service';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import {
  ADD_MASTER_SYMBOL,
  CREATE_USER,
  DELETE_MASTER,
  GET_CUSTOM_MASTER,
  GET_SYMBOLS,
  UPDATE_MASTER_SYMBOL,
  UPDATE_USER,
  USER_LIST,
  USER_ONE,
} from '../helper/queries';
import { ImageUploadServiceService } from '../image-upload-service.service';

let ELEMENT_DATA: any = [];

@Component({
  selector: 'app-symbol-master',
  templateUrl: './symbol-master.component.html',
  styleUrls: ['../users/users.component.scss'],
})
export class SymbolMasterComponent {
  selected = '';
  control = new FormControl('');
  streets: string[] = [];
  filteredStreets?: Observable<string[]>;
  @ViewChild('closeModal') closeModal: any;
  isLoading: boolean = true;
  searchTerm: string;
  searchKeyword: string = '';
  popUpFunction: boolean = true;

  passwordForm: FormGroup;
  passwordSubmit: boolean;

  noDataLength: number;
  userType: String;

  symbolImg: string;

  ngOnInit() {
    this.passwordForm = this._formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      value: ['', [Validators.required]],
      position: ['', [Validators.required]],
      // image: ['', [Validators.required]],
    });
  }

  displayedColumns: string[] = ['name', 'value', 'image', 'position', 'action'];
  dataSource: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private toastr: ToastrService,
    private imageUploadService: ImageUploadServiceService
  ) {
    this.get_custom_master();
    this.getUserDetails();
  }

  @ViewChild(MatSort)
  sort!: MatSort;

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getUserDetails(): void {
    const detailsString = localStorage.getItem('details');
    if (detailsString) {
      const userDetails = JSON.parse(detailsString);
      this.userType = userDetails?.roleType?.trim().toLowerCase();
    }
  }

  serachChange(): void {
    this.searchKeyword = this.searchTerm;
    this.get_custom_master();
  }

  get_custom_master(): void {
    this.graphqlService.client
      .query({
        query: GET_SYMBOLS,
        variables: { typeName: 'symbol' },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        this.noDataLength = data?.getSymbols?.length;
        const user: any = data?.getSymbols?.map((resp: any) => ({
          _id: resp?._id,
          name: resp?.name,
          value: resp?.value,
          image: this.imageUploadService.getFileName(resp?.image),
          imageUrl: resp?.image,
          position: resp?.position,
        }));
        this.dataSource = new MatTableDataSource(user);
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error);
      });
  }

  openImage(imageUrl: string | undefined): void {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    } else {
      // Handle cases where there is no image URL, if needed
      console.log('No image URL provided');
    }
  }

  onFileSelected(event: any) {
    const fileObj = event.target.files[0];
    this.imageUploadService
      .uploadImage(fileObj)
      .then((downloadURL) => {
        this.symbolImg = downloadURL;
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });
  }

  createSymbol(): void {
    if (this.passwordForm.valid) {
      const value = this.passwordForm.value;
      if (value?._id) {
        this.graphqlService.client
          .mutate({
            mutation: UPDATE_MASTER_SYMBOL,
            variables: {
              name: value?.name,
              value: value?.value,
              position: value?.position,
              image: this.symbolImg,
              id: value?._id,
            },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.get_custom_master();
            this.toastr.success('Update Successfully');
            this.passwordForm.reset();
            this.closeModal.nativeElement.click();
          })
          .catch((error: any) => {
            this.toastr.error(error.message);
            console.error(error);
          });
      } else {
        this.graphqlService.client
          .mutate({
            mutation: ADD_MASTER_SYMBOL,
            variables: {
              name: value?.name,
              value: value?.value,
              position: value?.position,
              image: this.symbolImg,
            },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.get_custom_master();
            this.toastr.success('Created Successfully');
            this.passwordForm.reset();
            this.closeModal.nativeElement.click();
          })
          .catch((error: any) => {
            this.toastr.error(error.message);
            console.error(error);
          });
      }
    }
  }

  deleteMaster(id: any): void {
    this.graphqlService.client
      .mutate({
        mutation: DELETE_MASTER,
        variables: {
          typeName: 'symbol',
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .then(() => {
        this.toastr.success('Deleted');
        this.get_custom_master();
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.toastr.error(error.message);
        this.isLoading = false;
      });
  }

  fetchData(data: any): void {
    this.symbolImg = data?.imageUrl;
    const newData = {
      _id: data?._id,
      name: data?.name,
      value: data?.value,
      position: data?.position,
    };
    this.passwordForm.patchValue(newData);
  }
}
