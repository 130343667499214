import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService } from '../graphql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import gql from 'graphql-tag';
import { CHANGE_PASSWORD } from '../helper/queries';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  loginForm: FormGroup;
  loginSubmit: boolean;
  isLoading: boolean = false;
  showPassword: boolean = false;
  pswEye: string = '../../assets/images/password-eye-close.png';
  showPasswordConfirm: boolean = false;
  pswEyeConfirm: string = '../../assets/images/password-eye-close.png';


  constructor(
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      localStorage.setItem('token', token);
    });
  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }




  togglePasswordVisibility(type: string) {
    if (type === 'new') {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.pswEye = '../../assets/images/password-eye.png'
      } else {
        this.pswEye = '../../assets/images/password-eye-close.png'
      }
    }
    if (type === 'confirm') {
      this.showPasswordConfirm = !this.showPasswordConfirm;
      if (this.showPasswordConfirm) {
        this.pswEyeConfirm = '../../assets/images/password-eye.png'
      } else {
        this.pswEyeConfirm = '../../assets/images/password-eye-close.png'
      }
    }
  }


  resetLogin(): void {
    if (this.loginForm.valid) {
      const value = this.loginForm.value;
      if (value?.password !== value?.confirmPassword) {
        this.toastr.error('Passwords must match...');
      } else {
        this.graphqlService.client
          .mutate({
            mutation: CHANGE_PASSWORD,
            variables: { updateUserInput: { password: value?.password } },
            fetchPolicy: 'network-only',
          })
          .then(() => {
            this.toastr.success('Password Changed Successfully');
            this.loginForm.reset();
            this.isLoading = false;
            this.router.navigate(['/login']);

          })
          .catch((error: any) => {
            this.toastr.error(error.message);
            this.isLoading = false;
          });
      }
    }
  }

}
