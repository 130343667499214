<header class="headerBgsecMain">
  <div class="headerTxtMainAll">
    <!-- <h3 *ngIf="!headerValue">Welcome To <label>LPA</label> </h3> -->
    <h3 *ngIf="!headerValue">Welcome To LPA</h3>
    <h3 *ngIf="headerValue"> {{ headerValue }} </h3>
    <!-- <div class="searchWrapperMain">
        <div class="searchWrapperPos">
<img src="../../assets/images/search-icon1.png">
            </div>
            <input type="text" placeholder="Search">
    </div> -->
  </div>
  <div class="profileMainAll">
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span> <img alt="pic" [src]=" this.picUrl"> </span> {{ userDetails?.userName || "___" }} <label>
          {{ userDetails?.roleType || "___" }} </label>
      </button>
      <ul class="dropdown-menu">
        <li (click)="setHeader('Users')"><a class="dropdown-item" routerLink="/settings">Settings</a></li>
        <!-- <li><a class="dropdown-item" routerLink="/settings">Change password</a></li> -->
        <li><a class="dropdown-item" (click)="logoutUser()">Logout</a></li>
      </ul>
    </div>
  </div>


</header>