<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec newstyle">

    <form [formGroup]="settingForm" (ngSubmit)="userUpdate()">
        <div class="divBgWhiteSet">
            <div class="row rowSpaceMain">
                <div class="col-sm-12 spaceMainall">
                    <div class="headerFleXmain">
                        <label>Profile Settings</label>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Name</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Name" formControlName="name">
                                    </mat-form-field>
                                </div>
                                <div class="s_error" *ngIf="
                        !settingForm.controls['name'].valid &&
                        (settingForm.controls['name'].dirty ||
                          settingForm.controls['name'].touched ||
                          settingSubmit)
                      ">
                                    <div class="s_validation" *ngIf="settingForm.controls['name'].hasError('required')">
                                        Name is required
                                    </div>

                                </div>
                            </div>
                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Email</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Email" formControlName="email" [readonly]="true">
                                    </mat-form-field>
                                </div>
                                <div class="s_error" *ngIf="
                        !settingForm.controls['email'].valid &&
                        (settingForm.controls['email'].dirty ||
                          settingForm.controls['email'].touched ||
                          settingSubmit)
                      ">
                                    <div class="s_validation"
                                        *ngIf="settingForm.controls['email'].hasError('required')">
                                        Email is required
                                    </div>
                                    <div class="s_validation" *ngIf="settingForm.controls['email'].hasError('pattern')">
                                        Invalid Email Id
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Role</span>
                                    <mat-form-field>
                                        <mat-select [(value)]="selected" formControlName="role">
                                            <mat-option value="">Select</mat-option>
                                            <mat-option value="manager">Manager</mat-option>
                                            <mat-option value="admin">Admin</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="s_error" *ngIf="
                        !settingForm.controls['role'].valid &&
                        (settingForm.controls['role'].dirty ||
                          settingForm.controls['role'].touched ||
                          settingSubmit)
                      ">
                                    <div class="s_validation" *ngIf="settingForm.controls['role'].hasError('required')">
                                        Role is required
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Position Title</span>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Title" formControlName="designation">
                                    </mat-form-field>
                                </div>
                                <div class="s_error" *ngIf="
                        !settingForm.controls['designation'].valid &&
                        (settingForm.controls['designation'].dirty ||
                          settingForm.controls['designation'].touched ||
                          settingSubmit)
                      ">
                                    <div class="s_validation"
                                        *ngIf="settingForm.controls['designation'].hasError('required')">
                                        Position Title is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Profile Upload</span>
                                    <input type="file" id="fileInput" (change)="onFileSelected($event)" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="formFieldMainAll">
                            <span> Profile Photo </span>
                            <div class="img-wrapper">
                                <img alt="" [src]=" this.picUrl">
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-sm-12 spaceMainall">
                    <div class="bottomBtnmain">
                        <button class="saveBtn" [disabled]="settingForm.invalid">Save</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
    <div class="divBgWhiteSet newstyleSettings">
        <form [formGroup]="passwordForm" (ngSubmit)="passwordUpdate()">
            <div class="row rowSpaceMain">
                <div class="col-sm-12 spaceMainall">
                    <div class="headerFleXmain">
                        <label>Change Password</label>
                    </div>
                </div>
                <div class="col-sm-4 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>Current Password</span>
                        <mat-form-field class="example-full-width">
                            <input type="password" matInput placeholder="Current Password"
                                formControlName="currentPassword" autocomplete="new-password">
                        </mat-form-field>
                        <div class="s_error" *ngIf="
            !passwordForm.controls['currentPassword'].valid &&
            (passwordForm.controls['currentPassword'].dirty ||
              passwordForm.controls['currentPassword'].touched ||
              passwordSubmit)
          ">
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['currentPassword'].hasError('required')">
                                Password is required
                            </div>
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['currentPassword'].hasError('minlength')">
                                Password must be at least 8 characters
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>New Password</span>
                        <mat-form-field class="example-full-width">
                            <input type="password" matInput placeholder="New Password" formControlName="newPassword"
                                autocomplete="new-password">
                        </mat-form-field>
                        <div class="s_error" *ngIf="
            !passwordForm.controls['newPassword'].valid &&
            (passwordForm.controls['newPassword'].dirty ||
              passwordForm.controls['newPassword'].touched ||
              passwordSubmit)
          ">
                            <div class="s_validation" *ngIf="passwordForm.controls['newPassword'].hasError('required')">
                                Password is required
                            </div>
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['newPassword'].hasError('minlength')">
                                Password must be at least 8 characters
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 spaceMainall">
                    <div class="formFieldMainAll">
                        <span>Confirm Password</span>
                        <mat-form-field class="example-full-width">
                            <input type="password" matInput placeholder="Confirm Password"
                                formControlName="confirmPassword" autocomplete="new-password">
                        </mat-form-field>
                        <div class="s_error" *ngIf="
            !passwordForm.controls['confirmPassword'].valid &&
            (passwordForm.controls['confirmPassword'].dirty ||
              passwordForm.controls['confirmPassword'].touched ||
              passwordSubmit)
          ">
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['confirmPassword'].hasError('required')">
                                Password is required
                            </div>
                            <div class="s_validation"
                                *ngIf="passwordForm.controls['confirmPassword'].hasError('minlength')">
                                Password must be at least 8 characters
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 spaceMainall">
                    <div class="bottomBtnmain">
                        <button class="saveBtn" [disabled]="passwordForm.invalid">Save</button>
                        <!-- <button class="cancelbtn">Cancel</button> -->
                    </div>
                </div>
            </div>
        </form>
    </div>


</section>