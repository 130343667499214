<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec">
    <div class="form-sec">
        <form [formGroup]="dataSheetForm">
            <div>
                <div class="rowSpaceMain">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="divBgWhiteSet ">
                                <div class="row rowSpaceMain">
                                    <div class="col-sm-12 spaceMainall">
                                        <div class="divBgWhiteSetSearch">
                                            <div class="searchWrapperMain">
                                                <div class="searchWrapperPos">
                                                    <img src="../../assets/images/search-icon1.png">
                                                </div>
                                                <input type="text"
                                                    placeholder="Search to import product information from NetSuite"
                                                    [(ngModel)]="searchQuery" (input)="searchDatasheet()"
                                                    (keydown.enter)="searchDatasheetDetails($event , 1)"
                                                    [matAutocomplete]="auto" [formControl]="control">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option *ngFor="let street of filteredStreets | async"
                                                        [value]="street" (click)="searchDatasheetDetails(street , 3)">
                                                        {{street}}
                                                    </mat-option>
                                                    <mat-hint *ngIf="searchLoading">Loading..</mat-hint>
                                                </mat-autocomplete>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- box 2 end -->

                            <div class="datasheet-form-preform-validation">
                                <ul *ngIf="!dataSheetForm.controls['displayName'].valid || !dataSheetForm.controls['type'].valid || !dataSheetForm.controls['vendor'].valid || !dataSheetForm.controls['representativeBrand'].valid || !dataSheetForm.controls['codePurchases'].valid">
                                    <li *ngIf="!dataSheetForm.controls['displayName'].valid &&(dataSheetForm.controls['displayName'].dirty ||dataSheetForm.controls['displayName'].touched ||dataSheetFormSubmit)">Please enter the <strong>Display Name</strong></li>
                                    <li *ngIf="!dataSheetForm.controls['type'].valid &&(dataSheetForm.controls['type'].dirty ||dataSheetForm.controls['type'].touched ||dataSheetFormSubmit)">Please select the <strong>Type</strong></li>
                                    <li *ngIf="!dataSheetForm.controls['vendor'].valid && (dataSheetForm.controls['vendor'].dirty || dataSheetForm.controls['vendor'].touched ||dataSheetFormSubmit)">Please select the <strong>Vendor</strong></li>
                                    <li *ngIf="!dataSheetForm.controls['representativeBrand'].valid &&(dataSheetForm.controls['representativeBrand'].dirty ||dataSheetForm.controls['representativeBrand'].touched ||dataSheetFormSubmit)">Please select the <strong>Representative Brand</strong></li>
                                    <li *ngIf="!dataSheetForm.controls['codePurchases'].valid &&(dataSheetForm.controls['codePurchases'].dirty ||dataSheetForm.controls['codePurchases'].touched ||dataSheetFormSubmit)">Please select the <strong>Vendor Name/Code-Purchases</strong></li>
                                </ul>
                            </div>

                            <section class="box divBgWhiteSet newstyle" id="datasheet-form-section1">
                                <div class="row">
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Display Name<span class="requiredColor">*</span></span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Display Name" formControlName="displayName"
                                                    [ngClass]="{'isInvalid': !dataSheetForm.controls['displayName'].valid &&(dataSheetForm.controls['displayName'].dirty ||dataSheetForm.controls['displayName'].touched)}">
                                            </mat-form-field>
                                        </div>
                                        <div class="s_error"
                                            *ngIf="!dataSheetForm.controls['displayName'].valid &&(dataSheetForm.controls['displayName'].dirty ||dataSheetForm.controls['displayName'].touched ||dataSheetFormSubmit)">
                                            <div class="s_validation"
                                                *ngIf="dataSheetForm.controls['displayName'].hasError('required')">
                                                Display Name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Type <span class="requiredColor">*</span></span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Type" formControlName="type">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="type?.value"
                                                        *ngFor="let type of listvalue?.type">
                                                        {{type?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="s_error"
                                            *ngIf="!dataSheetForm.controls['type'].valid &&(dataSheetForm.controls['type'].dirty ||dataSheetForm.controls['type'].touched ||dataSheetFormSubmit)">
                                            <div class="s_validation"
                                                *ngIf="dataSheetForm.controls['type'].hasError('required')">
                                                Type is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Vendor <span class="requiredColor">*</span> </span>
                                            <ng-select [items]="vendorOption" bindLabel="name" bindValue="value"
                                                [virtualScroll]="true" formControlName="vendor"
                                                (change)="onSelectionChange($event)" [placeholder]="placeholderText"
                                                [clearable]="true">
                                            </ng-select>
                                            <!-- <mat-form-field class="example-full-width">
                                        <mat-select placeholder="Select" formControlName="vendor"
                                            (selectionChange)="onSelectionChange()">
                                            <input matInput placeholder="Search..." #vendorSearch
                                                (input)="vendorSearchText()" class="inputSearchbox">
                                            <span class="search-icon">  
                                                <img src="../../assets/images/search-icon1.png">
                                            </span>
                                            <mat-option *ngIf="loadingVendors" disabled>Loading...</mat-option>
                                            <mat-option [value]="vendor?.value" *ngFor="let vendor of vendorOption">
                                                {{vendor?.name || "___"}} </mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                        </div>
                                        <div class="s_error"
                                            *ngIf="!dataSheetForm.controls['vendor'].valid && (dataSheetForm.controls['vendor'].dirty || dataSheetForm.controls['vendor'].touched ||dataSheetFormSubmit)">
                                            <div class="s_validation"
                                                *ngIf="dataSheetForm.controls['vendor'].hasError('required')">
                                                Vendor is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Subsidiary</span>
                                            <mat-form-field class="example-full-width" aria-disabled="true">
                                                <mat-select placeholder="Subsidiary" formControlName="subsidiary" [multiple]="true" #selectSubsidiary>
                                                    <mat-checkbox [(ngModel)]="allSelectedSubsidiary" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelectionSubsidiary()">
                                                        LPA Group
                                                    </mat-checkbox>
                                                    <mat-option [value]="subsidiary?.value" *ngFor="let subsidiary of listvalue?.subsidiary">{{subsidiary?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Representative Brand <span class="requiredColor">*</span> </span>
                                            <mat-form-field>
                                                <mat-select placeholder="Select" formControlName="representativeBrand">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="brand?.value"
                                                        *ngFor="let brand of listvalue?.representativeBrand">
                                                        {{brand?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="s_error"
                                            *ngIf="!dataSheetForm.controls['representativeBrand'].valid &&(dataSheetForm.controls['representativeBrand'].dirty ||dataSheetForm.controls['representativeBrand'].touched ||dataSheetFormSubmit)">
                                            <div class="s_validation"
                                                *ngIf="dataSheetForm.controls['representativeBrand'].hasError('required')">
                                                Representative Brand is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Vendor Name/Code-Purchases <span class="requiredColor">*</span>
                                            </span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Vendor Name/Code-Purchases"
                                                    formControlName="codePurchases">
                                            </mat-form-field>
                                        </div>
                                        <div class="s_error"
                                            *ngIf="!dataSheetForm.controls['codePurchases'].valid &&(dataSheetForm.controls['codePurchases'].dirty ||dataSheetForm.controls['codePurchases'].touched ||dataSheetFormSubmit)">
                                            <div class="s_validation"
                                                *ngIf="dataSheetForm.controls['codePurchases'].hasError('required')">
                                                Vendor Name/Code-Purchases is required
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Purchase Price </span>
                                    <mat-form-field class="example-full-width">
                                        <input type="number" matInput placeholder="Purchase Price"
                                            formControlName="purchasePrice">
                                    </mat-form-field>
                                </div>
                            </div> -->
                                </div>
                            </section>

                            <div class="datasheet-form-preform-validation">
                                <ul *ngIf="!dataSheetForm.controls['subCategory'].valid || !dataSheetForm.controls['CCT'].valid || !dataSheetForm.controls['fittingColor'].valid">
                                    <li *ngIf="!dataSheetForm.controls['subCategory'].valid &&(dataSheetForm.controls['subCategory'].dirty ||dataSheetForm.controls['subCategory'].touched ||dataSheetFormSubmit)">Please select the <strong>Sub-Category</strong></li>
                                    <li *ngIf="!dataSheetForm.controls['CCT'].valid &&(dataSheetForm.controls['CCT'].dirty ||dataSheetForm.controls['CCT'].touched ||dataSheetFormSubmit)">Please select the <strong>Colour Temperature</strong></li>
                                    <li *ngIf="!dataSheetForm.controls['fittingColor'].valid &&(dataSheetForm.controls['fittingColor'].dirty ||dataSheetForm.controls['fittingColor'].touched ||dataSheetFormSubmit)">Please select the <strong>Fitting Colour</strong></li>
                                </ul>
                            </div>
                            <!-- box 2 end -->

                            <section class="box divBgWhiteSet" id="datasheet-form-section2">
                                <div class="row">
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Product Name / Family Name</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Product Name"
                                                        formControlName="productName">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Model Number / Series</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Model Number"
                                                        formControlName="modelNumber">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Sub Category <span class="requiredColor">*</span> </span>
                                                <mat-form-field>
                                                    <mat-select placeholder="Select" formControlName="subCategory">
                                                        <mat-option value="">Select</mat-option>
                                                        <mat-option [value]="subCategory?.value"
                                                            *ngFor="let subCategory of listvalue?.subCategory">
                                                            {{subCategory?.name || "___"}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="s_error"
                                                *ngIf="!dataSheetForm.controls['subCategory'].valid &&(dataSheetForm.controls['subCategory'].dirty ||dataSheetForm.controls['subCategory'].touched ||dataSheetFormSubmit)">
                                                <div class="s_validation"
                                                    *ngIf="dataSheetForm.controls['subCategory'].hasError('required')">
                                                    Sub Category is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Source Power (W) </span>
                                                <mat-form-field class="example-full-width">
                                                    <input type="number" matInput placeholder="Source Power"
                                                        formControlName="sourcePower">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>System Power (W)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input type="number" matInput placeholder="System Wattage"
                                                        formControlName="systemWattage">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>LED Lifetime (Hours)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="LED Lifetime"
                                                        formControlName="LEDLifetime">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Colour Rendering Index (CRI>)</span>
                                                <mat-form-field class="example-full-width">
                                                    <mat-select placeholder="Select" formControlName="CRI">
                                                        <mat-option value="">Select</mat-option>
                                                        <mat-option [value]="cri?.value"
                                                            *ngFor="let cri of listvalue?.cri">
                                                            {{cri?.name || "___"}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Colour Temperature (CCT) <span class="requiredColor">*</span>
                                                </span>
                                                <mat-form-field class="example-full-width">
                                                    <mat-select placeholder="Select" formControlName="CCT">
                                                        <mat-option value="">Select</mat-option>
                                                        <mat-option [value]="cct?.value"
                                                            *ngFor="let cct of listvalue?.cct">
                                                            {{cct?.name || "___"}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div class="s_error"
                                                    *ngIf="!dataSheetForm.controls['CCT'].valid &&(dataSheetForm.controls['CCT'].dirty ||dataSheetForm.controls['CCT'].touched ||dataSheetFormSubmit)">
                                                    <div class="s_validation"
                                                        *ngIf="dataSheetForm.controls['CCT'].hasError('required')">
                                                        Colour Temperature is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Beam Angle (º)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Beam Angle"
                                                        formControlName="beamAngle">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Fitting Colour <span class="requiredColor">*</span> </span>
                                                <mat-form-field class="example-full-width">
                                                    <mat-select placeholder="Select" formControlName="fittingColor">
                                                        <mat-option value="">Select</mat-option>
                                                        <mat-option [value]="fittingColor?.value"
                                                            *ngFor="let fittingColor of listvalue?.fittingColor">
                                                            {{fittingColor?.name || "___"}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div class="s_error"
                                                    *ngIf="!dataSheetForm.controls['fittingColor'].valid &&(dataSheetForm.controls['fittingColor'].dirty ||dataSheetForm.controls['fittingColor'].touched ||dataSheetFormSubmit)">
                                                    <div class="s_validation"
                                                        *ngIf="dataSheetForm.controls['fittingColor'].hasError('required')">
                                                        Fitting Colour is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Product Image</span>
                                            <div class="browerUploadDiv">
                                                <div class="browerUploadDivBorder dragAndDropDiv"
                                                    *ngIf="!dataSheetForm.value.productImage">
                                                    <img alt="" src="../../assets/images/download-icon.png">

                                                    <div id="image-drop-zone" class="popUpInputDiv"
                                                        (dragover)="onDragOver($event)"
                                                        (drop)="onDropOut($event, 'productImage' )">
                                                        <h4>Drag and drop image</h4>
                                                        <input type="file" (change)="onFileChange($event)"
                                                            [formControl]="imgPopupfield" accept="image/*">
                                                    </div>

                                                    <h4>Or</h4>

                                                    <button type="button" (click)="openModel('productImage')">Browse
                                                        File</button>
                                                </div>
                                                <div class="browerUploadDivBorder "
                                                    *ngIf="dataSheetForm.value.productImage">
                                                    <div type="button" class="closeMainDiv"
                                                        (click)="clearImg('productImage')">
                                                        <img alt="" src="../../assets/images/close-icon1.png">
                                                    </div>
                                                    <img alt="" [src]="dataSheetForm.value.productImage">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 space-above ">
                                        <div class="row">
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Source Lumen (Lm)</span>
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput placeholder="Source Lumen"
                                                            formControlName="sourceLumen">
                                                    </mat-form-field>
                                                    <div class="s_error"
                                                        *ngIf="!dataSheetForm.controls['sourceLumen'].valid && (dataSheetForm.controls['sourceLumen'].dirty || dataSheetForm.controls['sourceLumen'].touched || dataSheetFormSubmit)">
                                                        <div class="s_validation"
                                                            *ngIf="dataSheetForm.controls['sourceLumen'].hasError('min') || dataSheetForm.controls['sourceLumen'].hasError('max') || dataSheetForm.controls['sourceLumen'].hasError('pattern')">
                                                            Value must be between 700 and 4900
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Ingress Protection Rating (IP)</span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Select"
                                                            formControlName="protectionRating">
                                                            <mat-option value="">Select</mat-option>
                                                            <mat-option [value]="protectionRating?.value"
                                                                *ngFor="let protectionRating of listvalue?.protectionRating">
                                                                {{protectionRating?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Voltage (V)</span>
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput placeholder="Voltage"
                                                            formControlName="supplyVoltage">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>System Lumen (Lm)</span>
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput placeholder="System Lumen"
                                                            formControlName="systemLumen">
                                                    </mat-form-field>
                                                    <div class="s_error"
                                                        *ngIf="!dataSheetForm.controls['systemLumen'].valid &&(dataSheetForm.controls['systemLumen'].dirty ||dataSheetForm.controls['systemLumen'].touched ||dataSheetFormSubmit)">
                                                        <div class="s_validation"
                                                            *ngIf="dataSheetForm.controls['systemLumen'].hasError('pattern')">
                                                            System Lumen must be an number
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Impact Protection Rating (IK)</span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Select" formControlName="impactRating">
                                                            <mat-option value="">Select</mat-option>
                                                            <mat-option [value]="impactRating?.value"
                                                                *ngFor="let impactRating of listvalue?.impactRating">
                                                                {{impactRating?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Accessories / Options</span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Select" formControlName="accessories"
                                                            [multiple]="true">
                                                            <mat-option [value]="accessories?.value"
                                                                *ngFor="let accessories of listvalue?.accessories">
                                                                {{accessories?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll lightColor">
                                                    <span>Efficacy (Lm/W)</span>
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput placeholder="Efficacy"
                                                            formControlName="efficacy" [ngModel]="calculateEfficacy()"
                                                            [readonly]="isReadOnly">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Main Material</span>
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput placeholder="Material"
                                                            formControlName="material">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Dimming Options</span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Select"
                                                            formControlName="dimmingOptions" [multiple]="true">
                                                            <mat-option [value]="dimmingOptions?.value"
                                                                *ngFor="let dimmingOptions of listvalue?.dimmingOptions">
                                                                {{dimmingOptions?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Optical System</span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Select"
                                                            formControlName="opticalSystem">
                                                            <mat-option value="">Select</mat-option>
                                                            <mat-option [value]="opticalSystem?.value"
                                                                *ngFor="let opticalSystem of listvalue?.opticalSystem">
                                                                {{opticalSystem?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Certifications</span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Certifications"
                                                            formControlName="certifications" [multiple]="true">
                                                            <mat-option [value]="certifications?.value"
                                                                *ngFor="let certifications  of listvalue?.certifications">
                                                                {{certifications?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 spaceMainall">
                                                <div class="formFieldMainAll">
                                                    <span>Datasheet Symbols</span>
                                                    <mat-form-field>
                                                        <mat-select placeholder="Selected" formControlName="symbols"
                                                            [multiple]="true">
                                                            <input matInput placeholder="Search..." #symbolSearch
                                                                (input)="symbolSearchText()" class="inputSearchbox">
                                                            <span class="search-icon">
                                                                <img src="../../assets/images/search-icon1.png">
                                                            </span>
                                                            <mat-option [value]="symbol?.image"
                                                                *ngFor="let symbol of symbolOption">
                                                                <img width="30px" height="30px" [src]="symbol?.image">
                                                                {{symbol?.name || "___"}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <div class="s_error"
                                                        *ngIf="!dataSheetForm.controls['symbols'].valid &&(dataSheetForm.controls['symbols'].dirty ||dataSheetForm.controls['symbols'].touched ||dataSheetFormSubmit)">
                                                        <div class="s_validation"
                                                            *ngIf="dataSheetForm.controls['symbols'].hasError('maxlength')">
                                                            You can select a maximum of 8 symbols.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </section>

                            <!-- box 3 end -->

                            <!-- box 4 start -->
                            <section class="box divBgWhiteSet">
                                <div class="row">
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Cut Out (mm)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Cut Out" formControlName="cutOut">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Diameter (mm)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Diameter" formControlName="diameter">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Height (mm)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Height" formControlName="height">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Length (mm)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Length"
                                                        formControlName="productLength">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Depth (mm)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Depth" formControlName="length">
                                                </mat-form-field>
                                                <div class="s_error"
                                                    *ngIf="!dataSheetForm.controls['length'].valid &&(dataSheetForm.controls['length'].dirty ||dataSheetForm.controls['length'].touched ||dataSheetFormSubmit)">
                                                    <div class="s_validation"
                                                        *ngIf="dataSheetForm.controls['length'].hasError('pattern')">
                                                        Depth must be an number
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Width (mm)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Width" formControlName="width">
                                                </mat-form-field>
                                                <div class="s_error"
                                                    *ngIf="!dataSheetForm.controls['width'].valid &&(dataSheetForm.controls['width'].dirty ||dataSheetForm.controls['width'].touched ||dataSheetFormSubmit)">
                                                    <div class="s_validation"
                                                        *ngIf="dataSheetForm.controls['width'].hasError('pattern')">
                                                        Width must be an number
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Tilt (º)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Tilt" formControlName="tilt">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Rotation (º)</span>
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Rotation" formControlName="rotation">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="col-sm-12 spaceMainall">
                                            <div class="formFieldMainAll">
                                                <span>Dimensions Diagram </span>
                                                <div class="browerUploadDiv">
                                                    <div class="browerUploadDivBorder dragAndDropDiv"
                                                        *ngIf="!dataSheetForm.value.measurementDiagram">
                                                        <img alt="" src="../../assets/images/download-icon.png">
                                                        <div id="image-drop-zone" class="popUpInputDiv"
                                                            (dragover)="onDragOver($event)"
                                                            (drop)="onDropOut($event, 'measurementDiagram' )">
                                                            <h4>Drag and drop image</h4>
                                                            <input type="file" (change)="onFileChange($event)"
                                                                [formControl]="imgPopupfield" accept="image/*">
                                                        </div>

                                                        <h4>Or</h4>
                                                        <button type="button"
                                                            (click)="openModel('measurementDiagram')">Browse
                                                            File</button>
                                                    </div>
                                                    <div class="browerUploadDivBorder"
                                                        *ngIf="dataSheetForm.value.measurementDiagram">
                                                        <div type="button" class="closeMainDiv"
                                                            (click)="clearImg('measurementDiagram')">
                                                            <img alt="" src="../../assets/images/close-icon1.png">
                                                        </div>
                                                        <img alt="" [src]="dataSheetForm.value.measurementDiagram">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <!-- box 4 end -->


                            <!-- box 5 start -->
                            <section class="box divBgWhiteSet">
                                <div class="row">
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Power Factor</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Power Factor" formControlName="diffuser">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>UGR(<) </span>
                                                    <mat-form-field class="example-full-width">
                                                        <input matInput placeholder="UGR" formControlName="UGR">
                                                    </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Light Output Ratio (%)</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="LOR" formControlName="LOR">
                                            </mat-form-field>
                                            <div class="s_error"
                                                *ngIf="!dataSheetForm.controls['LOR'].valid &&(dataSheetForm.controls['LOR'].dirty ||dataSheetForm.controls['LOR'].touched ||dataSheetFormSubmit)">
                                                <div class="s_validation"
                                                    *ngIf="dataSheetForm.controls['LOR'].hasError('pattern')">
                                                    Light Output Ratio must be an number
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Reflector Type</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Reflector Type" formControlName="reflectorType">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>MacAdam / SDCM (<) </span>
                                                    <mat-form-field class="example-full-width">
                                                        <mat-select placeholder="Select" formControlName="macAdam">
                                                            <mat-option value="">Select</mat-option>
                                                            <mat-option [value]="macAdam?.value"
                                                                *ngFor="let macAdam  of listvalue?.macadam">
                                                                {{macAdam?.name || "___"}} </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>COI Compliance</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="COI Compatibility"
                                                    formControlName="COICompatibility">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Reflector/Baffle Colour</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="reflectorColour">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="reflectorColour?.value"
                                                        *ngFor="let reflectorColour of listvalue?.reflectorBaffleColour">
                                                        {{reflectorColour?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Installation Method</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Installation Method"
                                                    formControlName="installationMethod">
                                            </mat-form-field>
                                        </div>
                                    </div> -->


                                </div>
                            </section>


                            <!-- box 5 end -->

                            <!-- box 6 start -->
                            <section class="box divBgWhiteSet">
                                <div class="row">
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Class of Protection</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="class">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="class?.value"
                                                        *ngFor="let class of listvalue?.classOfProtection">
                                                        {{class?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Power Connection</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="powerConnection">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="powerConnection?.value"
                                                        *ngFor="let powerConnection of listvalue?.powerConnection">
                                                        {{powerConnection?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Energy Class</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="energyClass">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="energyClass?.value"
                                                        *ngFor="let energyClass of listvalue?.energyClass">
                                                        {{energyClass?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Control Gear</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Control Gear" formControlName="controlGear">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Light Source Brand</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="lightSourceChipType">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="lightSourceChipType?.value"
                                                        *ngFor="let lightSourceChipType of listvalue?.lightSourceChipType">
                                                        {{lightSourceChipType?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Light Source Type</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="chipType">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="chipType?.value"
                                                        *ngFor="let chipType of listvalue?.chipType">
                                                        {{chipType?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Driver Brand/Code</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Driver Brand/Code"
                                                    formControlName="driverCode">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Warranty</span>
                                            <mat-form-field class="example-full-width">
                                                <mat-select placeholder="Select" formControlName="warranty">
                                                    <mat-option value="">Select</mat-option>
                                                    <mat-option [value]="warranty?.value"
                                                        *ngFor="let warranty of listvalue?.warranty">
                                                        {{warranty?.name || "___"}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Operating Temp (Ta)</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Operating Temp"
                                                    formControlName="operatingTemp">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- box 5 end -->










                            <section class="box divBgWhiteSet">
                                <div class="row">
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Project Name</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Project Name"
                                                    formControlName="projectName">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Type Reference</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Type Reference"
                                                    formControlName="typeReference">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Type Number</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Type Number" formControlName="typeNumber">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                </div>
                            </section>

                            <section class="box divBgWhiteSet">
                                <div class="row">
                                    <div class="col-sm-12 spaceMainall">
                                        <div class="formFieldMainAll">
                                            <span>Notes</span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Notes" formControlName="notes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="box divBgWhiteSet divBgWhiteSet-img-block">
                                <div class="row">
                                    <div class="col-sm-12 spaceMainall">
                                        <button type="button" hidden data-bs-toggle="modal" data-bs-target="#imgCrop"
                                            #openModalButton>Open
                                            Modal</button>
                                        <ul class="fileUploadDivWrapper">
                                            <li>
                                                <div class="formFieldMainAll box-img-block">
                                                    <span>Photometric Polar Image </span>
                                                    <div class="browerUploadDiv">
                                                        <div class="browerUploadDivBorder dragAndDropDiv"
                                                            *ngIf="!dataSheetForm.value.photometricImage">
                                                            <img alt="" src="../../assets/images/download-icon.png">
                                                            <div id="image-drop-zone" class="popUpInputDiv"
                                                                (dragover)="onDragOver($event)"
                                                                (drop)="onDropOut($event, 'photometricImage' )">
                                                                <h4>Drag and drop image</h4>
                                                                <input type="file" (change)="onFileChange($event)"
                                                                    [formControl]="imgPopupfield" accept="image/*">
                                                            </div>

                                                            <h4>Or</h4>
                                                            <button type="button"
                                                                (click)="openModel('photometricImage')">Browse
                                                                File</button>
                                                        </div>
                                                        <div class="browerUploadDivBorder"
                                                            *ngIf="dataSheetForm.value.photometricImage">
                                                            <div type="button" class="closeMainDiv"
                                                                (click)="clearImg('photometricImage')">
                                                                <img alt="" src="../../assets/images/close-icon1.png">
                                                            </div>
                                                            <img alt="" [src]="dataSheetForm.value.photometricImage">
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="formFieldMainAll box-img-block">
                                                    <span> Illuminance Cone Diagram </span>
                                                    <div class="browerUploadDiv">
                                                        <div class="browerUploadDivBorder dragAndDropDiv"
                                                            *ngIf="!dataSheetForm.value.illuminanceConeDiagram">
                                                            <img alt="" src="../../assets/images/download-icon.png">
                                                            <div id="image-drop-zone" class="popUpInputDiv"
                                                                (dragover)="onDragOver($event)"
                                                                (drop)="onDropOut($event, 'illuminanceConeDiagram' )">
                                                                <h4>Drag and drop image</h4>
                                                                <input type="file" (change)="onFileChange($event)"
                                                                    [formControl]="imgPopupfield" accept="image/*">
                                                            </div>

                                                            <h4>Or</h4>
                                                            <button type="button"
                                                                (click)="openModel('illuminanceConeDiagram')">Browse
                                                                File</button>
                                                        </div>
                                                        <div class="browerUploadDivBorder"
                                                            *ngIf="dataSheetForm.value.illuminanceConeDiagram">
                                                            <div type="button" class="closeMainDiv"
                                                                (click)="clearImg('illuminanceConeDiagram')">
                                                                <img alt="" src="../../assets/images/close-icon1.png">
                                                            </div>
                                                            <img alt=""
                                                                [src]="dataSheetForm.value.illuminanceConeDiagram">
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="formFieldMainAll box-img-block">
                                                    <span>In-situ photo </span>
                                                    <div class="browerUploadDiv">
                                                        <div class="browerUploadDivBorder dragAndDropDiv"
                                                            *ngIf="!dataSheetForm.value.inSituphoto">
                                                            <img alt="" src="../../assets/images/download-icon.png">
                                                            <div id="image-drop-zone" class="popUpInputDiv"
                                                                (dragover)="onDragOver($event)"
                                                                (drop)="onDropOut($event, 'inSituphoto' )">
                                                                <h4>Drag and drop image</h4>
                                                                <input type="file" (change)="onFileChange($event)"
                                                                    [formControl]="imgPopupfield" accept="image/*">
                                                            </div>

                                                            <h4>Or</h4>
                                                            <button type="button"
                                                                (click)="openModel('inSituphoto')">Browse
                                                                File</button>
                                                        </div>
                                                        <div class="browerUploadDivBorder"
                                                            *ngIf="dataSheetForm.value.inSituphoto">
                                                            <div type="button" class="closeMainDiv"
                                                                (click)="clearImg('inSituphoto')">
                                                                <img alt="" src="../../assets/images/close-icon1.png">
                                                            </div>
                                                            <img alt="" [src]="dataSheetForm.value.inSituphoto">
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="formFieldMainAll box-img-block">
                                                    <span> Other Requirements (e.g driver/box US market) </span>
                                                    <div class="browerUploadDiv">
                                                        <div class="browerUploadDivBorder dragAndDropDiv"
                                                            *ngIf="!dataSheetForm.value.otherRequirements">
                                                            <img alt="" src="../../assets/images/download-icon.png">
                                                            <div id="image-drop-zone" class="popUpInputDiv"
                                                                (dragover)="onDragOver($event)"
                                                                (drop)="onDropOut($event, 'otherRequirements' )">
                                                                <h4>Drag and drop image</h4>
                                                                <input type="file" (change)="onFileChange($event)"
                                                                    [formControl]="imgPopupfield" accept="image/*">
                                                            </div>

                                                            <h4>Or</h4>
                                                            <button type="button"
                                                                (click)="openModel('otherRequirements')">Browse
                                                                File</button>
                                                        </div>
                                                        <div class="browerUploadDivBorder"
                                                            *ngIf="dataSheetForm.value.otherRequirements">
                                                            <div type="button" class="closeMainDiv"
                                                                (click)="clearImg('otherRequirements')">
                                                                <img alt="" src="../../assets/images/close-icon1.png">
                                                            </div>
                                                            <img alt="" [src]="dataSheetForm.value.otherRequirements">
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>



                                    <!-- <div class="col-sm-12 spaceMainall">
                                <div class="formFieldMainAll">
                                    <span>Accessories Images</span>
                                    <div class="row addUploadWrapper">
                                        <div class="col-sm-2 fileUploadDivWrapper" *ngFor="let count of countArray; let i = index"
                                            [attr.data-index]="i">
                                            <div class="browerUploadDiv">
                                                <div class="browerUploadDivBorder"
                                                    *ngIf="!dataSheetForm?.value?.accessoriesImg[(+i)]">
                                                    <img alt="" src="../../assets/images/download-icon.png">
                                                    <h4>Drag and drop image</h4>
                                                    <h4>Or</h4>
                                                    <button (click)="openModel('accessoriesImg')">Browse File</button>
                                                </div>
                                                <div class="browerUploadDivBorder"
                                                    *ngIf="dataSheetForm?.value?.accessoriesImg[(+i)]">
                                                    <div type="button" class="closeMainDiv closeMainDivSmall"
                                                        (click)="clearImgAss(i)">
                                                        <img alt="" src="../../assets/images/close-icon1.png">
                                                    </div>
                                                    <img alt="" [src]="dataSheetForm.value?.accessoriesImg[+i]">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 fileUploadDivWrapper">
                                            <div class="addPluseBtn" (click)="onAssClick()">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="47" height="95" viewBox="0 0 47 95">
                                                    <text data-name="+" transform="translate(0 71)"
                                                        style="fill:#cecece;font-size:68px;font-family:Poppins-Regular,Poppins">
                                                        <tspan x="0" y="0">+</tspan>
                                                    </text>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                                </div>
                            </section>
                        </div>
                        <div class="col-md-4">
                            <!-- box1 start -->
                            <section class="box divBgWhiteSet newstyle" id="targetSection"
                                [ngClass]="{'sticky-section': isSticky}">
                                <div class="row">
                                    <div class="col-sm-3 spaceMainall spaceFull">
                                        <div class="headerFleXmain">
                                            <label>Product Specification</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 spaceMainall spaceFull">
                                        <div class="headerFleXmain">
                                            <div class="d-flex">
                                                <button class="addNewBtn spaceBtn" *ngIf="dataSheetType ==='view' "
                                                    (click)="enableEdit()">Edit</button>
                                                <button *ngIf="openPdf" class="addNewBtn"
                                                    (click)="openDownload()">Download
                                                    Datasheet</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 spaceMainall">
                                        <div class="formFieldMainAll lightColor">
                                            <span>Item Code (Auto Generated) </span>
                                            <mat-form-field class="example-full-width">
                                                <input matInput placeholder="Item Code" formControlName="itemCode"
                                                    [readonly]="isReadOnly">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 spaceMainall">
                                        <div class="formFieldMainAll lightColor">
                                            <span>Purchase Description (Auto Generated) </span>
                                            <mat-form-field class="example-full-width">
                                                <textarea placeholder="Purchase Description" matInput
                                                    formControlName="purchaseDescription"
                                                    [readonly]="isReadOnly"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 spaceMainall">
                                        <div class="formFieldMainAll lightColor">
                                            <span>Sales Description (Auto Generated) </span>
                                            <mat-form-field class="example-full-width">
                                                <textarea placeholder="Sales  Description" matInput
                                                    formControlName="salesDescription" [value]="salesDescriptionFormat"
                                                    [readonly]="isReadOnly"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- box1 end -->
                        </div>
                    </div>



                    <!-- Error Messages -->
                    <div class="datasheet-form-presubmit-validation">
                        <ul>
                            <li *ngIf="!dataSheetForm.controls['displayName'].valid &&(dataSheetForm.controls['displayName'].dirty ||dataSheetForm.controls['displayName'].touched ||dataSheetFormSubmit)">Please enter the <strong>Display Name</strong></li>
                            <li *ngIf="!dataSheetForm.controls['type'].valid &&(dataSheetForm.controls['type'].dirty ||dataSheetForm.controls['type'].touched ||dataSheetFormSubmit)">Please select the <strong>Type</strong></li>
                            <li *ngIf="!dataSheetForm.controls['vendor'].valid && (dataSheetForm.controls['vendor'].dirty || dataSheetForm.controls['vendor'].touched ||dataSheetFormSubmit)">Please select the <strong>Vendor</strong></li>
                            <li *ngIf="!dataSheetForm.controls['representativeBrand'].valid &&(dataSheetForm.controls['representativeBrand'].dirty ||dataSheetForm.controls['representativeBrand'].touched ||dataSheetFormSubmit)">Please select the <strong>Representative Brand</strong></li>
                            <li *ngIf="!dataSheetForm.controls['codePurchases'].valid &&(dataSheetForm.controls['codePurchases'].dirty ||dataSheetForm.controls['codePurchases'].touched ||dataSheetFormSubmit)">Please select the <strong>Vendor Name/Code-Purchases</strong></li>
                            <li *ngIf="!dataSheetForm.controls['subCategory'].valid &&(dataSheetForm.controls['subCategory'].dirty ||dataSheetForm.controls['subCategory'].touched ||dataSheetFormSubmit)">Please select the <strong>Sub-Category</strong></li>
                            <li *ngIf="!dataSheetForm.controls['CCT'].valid &&(dataSheetForm.controls['CCT'].dirty ||dataSheetForm.controls['CCT'].touched ||dataSheetFormSubmit)">Please select the <strong>Colour Temperature</strong></li>
                            <li *ngIf="!dataSheetForm.controls['fittingColor'].valid &&(dataSheetForm.controls['fittingColor'].dirty ||dataSheetForm.controls['fittingColor'].touched ||dataSheetFormSubmit)">Please select the <strong>Fitting Colour</strong></li>
                        </ul>
                    </div>

                    <!-- box 3 start -->





                    <div class="col-sm-12 spaceMainall" *ngIf="dataSheetType !=='view'">
                        <div class="bottomBtnmain">
                            <button class="saveBtn" [disabled]="isLoading" (click)="dataSheetSubmit()">{{ dataSheetType
                                == "duplicate" ? "Duplicate" :
                                dataSheetDetails?.netSuiteId ? "Update" :
                                "Save"}}</button>
                            <button type="reset" class="cancelbtn" (click)="clearForm()" #clearBtn>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>


</section>


<!-- pop Up for image crop-->
<div class="usersPopupdiv">
    <div class="modal fade" id="imgCrop" tabindex="-1" aria-labelledby="imgCrop" aria-hidden="true"
        data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal
                        (click)="clearPopup()">
                        <img alt="" src="../../assets/images/close-icon1.png">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="formFieldMainAll">
                            <span>Upload Image</span>
                            <!-- <input type="file" (change)="onFileChange($event)" [formControl]="imgPopupfield"> -->
                            <div id="image-drop-zone" class="popUpInputDiv" (dragover)="onDragOver($event)"
                                (drop)="onDrop($event)">
                                <p>Drag and drop an image here or click to select an image</p>
                                <input type="file" (change)="onFileChange($event)" [formControl]="imgPopupfield"
                                    accept="image/*">
                            </div>
                            <div class="previewImg">
                                <image-cropper [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true"
                                    [aspectRatio]="4 / 4" [resizeToWidth]="1024" format="png"
                                    (imageCropped)="cropImg($event)" (imageLoaded)="imgLoad()"
                                    (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()" outputType="base64"
                                    [transform]="transform">
                                </image-cropper>
                                <img [src]="cropImgPreview" />
                            </div>
                            <div class="col-md-12 zoomContainer">
                                <img src="/assets/images/zoomOutIcon.png" alt="-" (click)="zoomOut()" class="me-4 mb-3">
                                <img src="/assets/images/zoomInIcon.png" alt="+" (click)="zoomIn()" class="me-4 mb-3">
                            </div>
                            <button class="addUserSend" (click)="uploadImage()">Upload</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>