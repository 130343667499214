<app-loader [showLoader]="isLoading"></app-loader>
<section class="secBgMain1">
  <div class="flexBoxMain">
    <div class="welcomeBackDiv">
      <div class="welcomeFlexBack">
        <h1>
          Welcome
          <span>Back</span>
        </h1>
        <p>
          <!-- Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. -->
        </p>
      </div>
    </div>
    <div class="loginBgBox">
      <img alt="" src="../../assets/images/logo-img.png" />
      <h2>Reset Password to LPA</h2>
      <form [formGroup]="loginForm" (ngSubmit)="resetLogin()">
        <input type="text" placeholder="Email" formControlName="email" />
        <div class="s_error" *ngIf="
            !loginForm.controls['email'].valid &&
            (loginForm.controls['email'].dirty ||
              loginForm.controls['email'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['email'].hasError('required')">
            Email is required
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['email'].hasError('pattern')">
            Invalid Email Id
          </div>
        </div>
        <div class="foprgotPasswarrpper">
          <button type="submit" [disabled]="loginForm.invalid">Reset Password</button>
          <a routerLink="/login">Login</a>
        </div>
      </form>
    </div>
  </div>
</section>