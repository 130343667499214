<app-loader [showLoader]="isLoading"></app-loader>
<section class="secBgMain1">
  <div class="flexBoxMain">
    <div class="welcomeBackDiv">
      <div class="welcomeFlexBack">
        <h1>
          Welcome Back
          <!-- <span></span> -->
        </h1>
        <p>
          <!-- Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. -->
        </p>
      </div>
    </div>
    <div class="loginBgBox">
      <img alt="" src="../../assets/images/logo-img.png" />
      <h2>Login to LPA</h2>
      <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
        <input type="text" placeholder="Email" formControlName="email" />
        <div class="s_error" *ngIf="
            !loginForm.controls['email'].valid &&
            (loginForm.controls['email'].dirty ||
              loginForm.controls['email'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['email'].hasError('required')">
            Email is required
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['email'].hasError('pattern')">
            Invalid Email Id
          </div>
        </div>
        <div class="pswBox">
          <input type="password" placeholder="Password" autocomplete="new-password" formControlName="password"  [type]="showPassword ? 'text' : 'password'"/>
          <img  [src]="pswEye" (click)="togglePasswordVisibility()">
        </div>
        <div class="s_error" *ngIf="
            !loginForm.controls['password'].valid &&
            (loginForm.controls['password'].dirty ||
              loginForm.controls['password'].touched ||
              loginSubmit)
          ">
          <div class="s_validation" *ngIf="loginForm.controls['password'].hasError('required')">
            Password is required
          </div>
          <div class="s_validation" *ngIf="loginForm.controls['password'].hasError('minlength')">
            Password must be at least 8 characters
          </div>
        </div>
        <div class="foprgotPasswarrpper">
          <button type="submit" [disabled]="loginForm.invalid">LOGIN</button>
          <a routerLink="/forgotpassword">Forgot Password?</a>
        </div>
      </form>
    </div>
  </div>
</section>