import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class MyAuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const helper = new JwtHelperService();
    const uuid = localStorage.getItem('uuid')
    const token = localStorage.getItem('token');
    let userType
    const detailsString = localStorage.getItem("details")
    if (detailsString) {
      const userDetails = JSON.parse(detailsString);
      userType = userDetails?.roleType?.trim().toLowerCase()
    }
    if (uuid && token) {
      const isTokenExpired = helper.isTokenExpired(token);
      if (isTokenExpired) {
        this.router.navigate(['/login']);
        return false;
      }

      if (state?.url === '/users' && (userType === 'manager' )) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
