<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec">
  <div class="form-sec">
    <form [formGroup]="masterForm">

      <section class="box divBgWhiteSet">

        <div class="formFieldMainAll">
          <span>Master Data List <span class="requiredColor">*</span> </span>
          <mat-form-field class="example-full-width">
            <mat-select placeholder="Select" formControlName="master">
              <mat-option value="">Select</mat-option>
              <mat-option [value]="option?.master" *ngFor="let option of masterOptionsSorted">{{option?.label || "___"}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="s_error" *ngIf="(validForm === false || masterFormSubmit)">
          <div class="s_validation" *ngIf="!validForm">Please select the Master Data List to sync</div>
        </div>

      </section>

      <div class="bottomBtnmain">
        <button class="saveBtn" [disabled]="isLoading" (click)="syncNetsuite()">Sync from Netsuite</button>
      </div>

    </form>
  </div>
</section>
