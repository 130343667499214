import * as dayjs from 'dayjs'
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ViewChild } from '@angular/core';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { GraphqlService } from '../graphql.service';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { DATA_SHEETS } from '../helper/queries';
import { Router } from '@angular/router';
export interface PeriodicElement {
  userId: number;
  name: string;
  weight: number;
  symbol: string;
  action: string;
  datasheet: string;
}
const ELEMENT_DATA: any = [];

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrls: ['./datasheet.component.scss'],
})
export class DatasheetComponent implements OnInit {
  control = new FormControl('');
  streets: string[] = [];
  filteredStreets?: Observable<string[]>;
  isLoading: boolean = true;

  searchTerm: string;
  searchKeyword: string = '';
  viewType: string;

  noDataLength: number;

  clientLocale = navigator.language || 'en-US';

  serachChange(): void {
    this.searchKeyword = this.searchTerm;
    this.dataSheetslist();
  }

  ngOnInit() {
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets?.filter((street) =>
      this._normalizeValue(street).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  displayedColumns: string[] = [
    'userId',
    'date',
    'productCode',
    'brand',
    'view',
    'action',
  ];
  dataSource: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private graphqlService: GraphqlService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.dataSheetslist();
  }

  dataSheetslist(): void {
    this.isLoading = true;
    this.graphqlService.client
      .query({
        query: DATA_SHEETS,
        variables: {
          listUsersInput: { limit: 1000, offset: 0 },
          search: this.searchKeyword,
        },
        fetchPolicy: 'network-only',
      })
      .then((result: any) => {
        this.noDataLength = result?.data?.dataSheets?.length;
        const user: any = result?.data?.dataSheets?.map((resp: any) => ({
          _id: resp?._id,
          userId: resp?.userName,
          date : resp?.createdAt ? dayjs(resp?.createdAt).format('D MMM YYYY') : '',
          // date: new Date(resp?.createdAt)?.toLocaleDateString(this.clientLocale, {
          //   day: '2-digit',
          //   month: '2-digit',
          //   year: 'numeric',
          // }),
          productCode: resp?.itemCode,
          brand: resp?.representativebrand,
          view: resp?.url || null,
        }));
        this.dataSource = new MatTableDataSource(user);
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error);
      });
  }

  @ViewChild(MatSort)
  sort!: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  // }

  openDataSheet(data: any): void {
    if (data) {
      window.open(data, '_blank');
    } else {
      this.toastr.error('Datasheet not generate... Try Again');
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onAction(type: string, code: string) {
    // const selectedValue = (event.target as HTMLSelectElement).value;
    const selectedValue = type
    switch (selectedValue) {
      case 'view':
        this.router.navigate(['/datasheet'], {
          queryParams: { code: code, type: selectedValue },
          queryParamsHandling: 'merge',
        });
        break;
      case 'edit':
        this.router.navigate(['/datasheet'], {
          queryParams: { code: code, type: selectedValue },
          queryParamsHandling: 'merge',
        });
        break;
      case 'duplicate':
        this.router.navigate(['/datasheet'], {
          queryParams: { code: code, type: selectedValue },
          queryParamsHandling: 'merge',
        });
        break;
      default:
        this.toastr.error('Something Went Wrong... Try Again');
        break;
    }
  }
}
