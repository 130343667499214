import { Component } from '@angular/core';

@Component({
  selector: 'app-pagelayout',
  templateUrl: './pagelayout.component.html',
  styleUrls: ['./pagelayout.component.scss']
})
export class PagelayoutComponent {
  userType: String;
  constructor() { this.getUserDetails() }

  getUserDetails(): void {
    const detailsString = localStorage.getItem("details")
    if (detailsString) {
      const userDetails = JSON.parse(detailsString);
      this.userType = userDetails?.roleType?.trim().toLowerCase()
    }
  }
}
