<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec">
  <div class="serachMainAllWrapper">
    <div class="searchDiv">
      <form class="example-form">
        <input type="text" placeholder="Search for a datasheet" class="example-input" [(ngModel)]="searchTerm"
          (input)="serachChange()" [ngModelOptions]="{ standalone: true }">
        <!-- <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
            {{street}}
          </mat-option>
        </mat-autocomplete> -->
      </form>
    </div>
    <!-- <button routerLink="/datasheet">ADD NEW DATASHEET</button> -->
  </div>
  <div class="dataSheetTable">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8">

        <!-- userId Column -->
        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            User
          </th>
          <td mat-cell *matCellDef="let element"> {{element.userId || '___'}} </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Date
          </th>
          <td mat-cell *matCellDef="let element"> {{element.date || '___'}} </td>
        </ng-container>

        <!-- productCode Column -->
        <ng-container matColumnDef="productCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Product Code">
            Product code
          </th>
          <td mat-cell *matCellDef="let element"> {{element.productCode || '___'}} </td>
        </ng-container>

        <!-- brand Column -->
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Brand">
            Brand
          </th>
          <td mat-cell *matCellDef="let element"> {{element.brand?.name || '___'}} </td>
        </ng-container>

        <!-- view Column -->
        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>
            Datasheet
          </th>
          <td mat-cell *matCellDef="let element">
            <img 
              *ngIf="element?.view"
              class="pdf-datasheet"
              alt="Download Datasheet" 
              src="/assets/images/pdf-icon.png" 
              (click)="openDataSheet(element?.view)"
              />
            <span *ngIf="!element?.view">Datasheet not generated</span>
          </td>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
            Action
          </th>
          <!-- <td mat-cell *matCellDef="let element let i = index">
            <div class="editDropDown">
              <select (change)="onAction($event , dataSource?.data?.[i]?.productCode)">
                <option value="">Select</option>
                <option value="view">View</option>
                <option value="edit">Edit</option>
              </select>
            </div>
          </td> -->
          <td mat-cell *matCellDef="let element  let i = index">
            <div class="dropMenuMain">
              <mat-menu #menu="matMenu">
                <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="onAction('view' , dataSource?.data?.[i]?.productCode)">View</button>
                <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="onAction('edit', dataSource?.data?.[i]?.productCode)">Edit</button>
                <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="onAction('duplicate', dataSource?.data?.[i]?.productCode)">Duplicate</button>
              </mat-menu>

              <svg mat-button [matMenuTriggerFor]="menu" xmlns="http://www.w3.org/2000/svg" width="5.991"
                height="20.968" viewBox="0 0 5.991 20.968">
                <defs>
                  <clipPath id="8n8m98hiaa">
                    <path data-name="Rectangle 2150" transform="translate(0 -.001)" style="fill:#002643"
                      d="M0 0h5.991v20.968H0z" />
                  </clipPath>
                </defs>
                <g data-name="Group 1474">
                  <g data-name="Group 1473" style="clip-path:url(#8n8m98hiaa)" transform="translate(0 .001)">
                    <path data-name="Path 1326"
                      d="M0 2.972V.82A.749.749 0 0 1 .813 0h4.375a.748.748 0 0 1 .8.806v4.375a.747.747 0 0 1-.8.808h-4.4A.747.747 0 0 1 0 5.2V2.977"
                      style="fill:#002643" />
                    <path data-name="Path 1327"
                      d="M0 10.472V8.32a.752.752 0 0 1 .824-.831h4.351a.752.752 0 0 1 .814.817v4.352a.751.751 0 0 1-.812.82H.802a.749.749 0 0 1-.8-.807v-2.2"
                      style="fill:#002643" />
                    <path data-name="Path 1328"
                      d="M3.006 14.976h2.152a.756.756 0 0 1 .831.825v4.351a.751.751 0 0 1-.818.814H.82a.753.753 0 0 1-.82-.813v-4.376a.745.745 0 0 1 .806-.8h2.2"
                      style="fill:#002643" />
                  </g>
                </g>
              </svg>
            </div>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="noData-msg" *ngIf="!noDataLength">
      <img src="../../assets/images/noDataIcon.png" alt="noData">
    </div>
  </div>
</section>