<app-loader [showLoader]="isLoading"></app-loader>
<section class="addDatSheetCommonSec">
  <div class="serachMainAllWrapper">
    <div class="searchDiv">
      <form class="example-form">
        <input type="text" placeholder="Search" class="example-input" [(ngModel)]="searchTerm" (input)="serachChange()"
          [ngModelOptions]="{ standalone: true }">
      </form>
    </div>
    <button type="button" data-bs-toggle="modal" data-bs-target="#AddModal"
      *ngIf="formMasterType.get('masterType')?.value === 'lightSourceChipType'">ADD</button>

    <form [formGroup]="formMasterType">
      <mat-form-field>
        <mat-select formControlName="masterType">
          <mat-option value="subCategory">SUB CATEGORY</mat-option>
          <mat-option value="cri">CRI</mat-option>
          <mat-option value="cct">CCT</mat-option>
          <mat-option value="accessories">ACCESSORIES</mat-option>
          <mat-option value="dimmingOptions">DIMMING OPTIONS</mat-option>
          <mat-option value="fittingColor">FITTING COLOR</mat-option>
          <mat-option value="lightSourceChipType">LIGHT SOURCE BRAND</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

  </div>
  <div class="dataSheetTable">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8">

        <!-- Sl Column -->
        <ng-container matColumnDef="slNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Sl No
          </th>
          <td mat-cell *matCellDef="let element let i = index"> {{ i + 1 }} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
            Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.name || "___"}} </td>
        </ng-container>

        <!--  Ref Code Column -->
        <ng-container matColumnDef="refCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            Reference Code
          </th>
          <td mat-cell *matCellDef="let element"> {{element?.refCode || "___"}} </td>
        </ng-container>

        <!--  action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            Action
          </th>
          <td mat-cell *matCellDef="let element  let i = index">
            <div class="dropMenuMain">
              <mat-menu #menu="matMenu">
                <button mat-menu-item data-bs-toggle="modal" data-bs-target="#exampleModal"
                  (click)="fetchRefCode(element)">Update</button>
                <button mat-menu-item (click)="deleteMaster(element?._id)"
                  *ngIf="formMasterType.get('masterType')?.value === 'lightSourceChipType' ">Delete</button>
              </mat-menu>
              <svg mat-button [matMenuTriggerFor]="menu" xmlns="http://www.w3.org/2000/svg" width="5.991"
                height="20.968" viewBox="0 0 5.991 20.968">
                <defs>
                  <clipPath id="8n8m98hiaa">
                    <path data-name="Rectangle 2150" transform="translate(0 -.001)" style="fill:#002643"
                      d="M0 0h5.991v20.968H0z" />
                  </clipPath>
                </defs>
                <g data-name="Group 1474">
                  <g data-name="Group 1473" style="clip-path:url(#8n8m98hiaa)" transform="translate(0 .001)">
                    <path data-name="Path 1326"
                      d="M0 2.972V.82A.749.749 0 0 1 .813 0h4.375a.748.748 0 0 1 .8.806v4.375a.747.747 0 0 1-.8.808h-4.4A.747.747 0 0 1 0 5.2V2.977"
                      style="fill:#002643" />
                    <path data-name="Path 1327"
                      d="M0 10.472V8.32a.752.752 0 0 1 .824-.831h4.351a.752.752 0 0 1 .814.817v4.352a.751.751 0 0 1-.812.82H.802a.749.749 0 0 1-.8-.807v-2.2"
                      style="fill:#002643" />
                    <path data-name="Path 1328"
                      d="M3.006 14.976h2.152a.756.756 0 0 1 .831.825v4.351a.751.751 0 0 1-.818.814H.82a.753.753 0 0 1-.82-.813v-4.376a.745.745 0 0 1 .806-.8h2.2"
                      style="fill:#002643" />
                  </g>
                </g>
              </svg>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="noData-msg" *ngIf="!noDataLength">
      <img src="../../assets/images/noDataIcon.png" alt="noData">
    </div>
  </div>
</section>

<!-- pop Up -->
<div class="usersPopupdiv">
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">

          <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>

            <img alt="" src="../../assets/images/close-icon1.png">
          </button>
        </div>
        <div class="modal-body">
          <div class="row rowSpaceMain">
            <form [formGroup]="formRefCode" (ngSubmit)="updateRefCode()">
              <div class=" col-sm-12 spaceMainall">
                <h2>Update Reference</h2>
                <div class=" formFieldMainAll">
                  <span>Reference Code</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Reference Code" formControlName="code">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !formRefCode.controls['code'].valid &&
            (formRefCode.controls['code'].dirty ||
              formRefCode.controls['code'].touched ||
              formRefCodeFormSubmit)
          ">
                    <div class="s_validation" *ngIf="formRefCode.controls['code'].hasError('required')">
                      Reference Code is required
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <button class="addUserSend" [disabled]="formRefCode.invalid"> UPDATE </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add pop Up -->
<div class="usersPopupdiv">
  <div class="modal fade" id="AddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-closeMain" data-bs-dismiss="modal" aria-label="Close" #closeModal>
            <img alt="" src="../../assets/images/close-icon1.png">
          </button>
        </div>
        <div class="modal-body">
          <div class="row rowSpaceMain">
            <form [formGroup]="formAddMaster" (ngSubmit)="addMasterData()">
              <div class=" col-sm-12 spaceMainall">
                <h2>ADD LIGHT SOURCE BRAND</h2>
                <div class=" formFieldMainAll">
                  <span>Name</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Name" formControlName="name">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !formAddMaster.controls['name'].valid &&
            (formAddMaster.controls['name'].dirty ||
              formAddMaster.controls['name'].touched ||
              formAddMasterSubmit)
          ">
                    <div class="s_validation" *ngIf="formAddMaster.controls['name'].hasError('required')">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class=" formFieldMainAll">
                  <span>Reference Code</span>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Reference Code" formControlName="code">
                  </mat-form-field>
                  <div class="s_error" *ngIf="
            !formAddMaster.controls['code'].valid &&
            (formAddMaster.controls['code'].dirty ||
              formAddMaster.controls['code'].touched ||
              formAddMasterSubmit)
          ">
                    <div class="s_validation" *ngIf="formAddMaster.controls['code'].hasError('required')">
                      Reference Code is required
                    </div>
                  </div>
                </div>
                <div class="formFieldMainAll">
                  <button class="addUserSend" [disabled]="formAddMaster.invalid"> ADD </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>