import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GraphqlService } from '../graphql.service';
import { ToastrService } from 'ngx-toastr';
import {
  ADD_MASTER,
  DELETE_MASTER,
  MASTER,
  UPDATE_REFCODE,
} from '../helper/queries';

@Component({
  selector: 'app-redCode',
  templateUrl: './refCode.component.html',
  styleUrls: ['./refCode.component.scss'],
})
export class RefCodeComponent implements OnInit {
  control = new FormControl('');
  @ViewChild('closeModal') closeModal: any;
  isLoading: boolean = false;
  searchTerm: string;
  passwordForm: FormGroup;
  noDataLength: number;
  formMasterType: FormGroup;
  formRefCode: FormGroup;
  formRefCodeFormSubmit: boolean;
  fetchRefData: null;
  formAddMaster: FormGroup;
  formAddMasterSubmit: boolean;

  ngOnInit() {
    this.formMasterType = this._formBuilder.group({
      masterType: [''],
    });

    // Subscribe to value changes
    this.formMasterType?.get('masterType')?.valueChanges?.subscribe((value) => {
      this.master(value);
    });

    this.formRefCode = this._formBuilder.group({
      code: ['', [Validators.required]],
    });
    this.formAddMaster = this._formBuilder.group({
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
  }

  displayedColumns: string[] = ['slNo', 'name', 'refCode', 'action'];
  dataSource: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private toastr: ToastrService
  ) {}

  @ViewChild(MatSort)
  sort!: MatSort;

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  master(type: string): void {
    this.isLoading = true;
    this.graphqlService.client
      .query({
        query: MASTER,
        fetchPolicy: 'no-cache',
      })
      .then((result: any) => {
        this.noDataLength = result?.data?.master?.[type]?.length;
        const refCode: any = result?.data?.master?.[type]?.map((resp: any) => ({
          _id: resp?._id,
          name: resp?.name,
          value: resp?.value,
          refCode: resp?.refCode,
        }));
        this.dataSource = new MatTableDataSource(refCode);
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error);
      });
  }

  serachChange(): void {
    const filterValue = this.searchTerm.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  closeModel(): void {
    this.closeModal.nativeElement.click();
  }

  fetchRefCode(data: any): void {
    this.fetchRefData = data;
    // this.formRefCode.controls['code'].clearValidators();
    // this.formRefCode.controls['code'].updateValueAndValidity();
    const newData = {
      code: data?.refCode,
    };
    this.formRefCode.patchValue(newData);
  }

  updateRefCode(): void {
    if (this.formRefCode.valid) {
      this.isLoading = true;
      const data: any = this.fetchRefData;
      const value = this.formRefCode.value;
      const type = this.formMasterType.value;
      this.graphqlService.client
        .mutate({
          mutation: UPDATE_REFCODE,
          variables: {
            id: data?._id,
            type: type?.masterType,
            name: data?.name,
            refCode: value?.code,
          },
          fetchPolicy: 'network-only',
        })
        .then(() => {
          this.toastr.success('Updated');
          this.master(type?.masterType);
          this.isLoading = false;
          this.closeModel();
        })
        .catch((error: any) => {
          this.toastr.error(error.message);
          this.isLoading = false;
        });
    }
  }

  addMasterData(): void {
    if (this.formAddMaster.valid) {
      this.isLoading = true;
      const value = this.formAddMaster.value;
      this.graphqlService.client
        .mutate({
          mutation: ADD_MASTER,
          variables: {
            type: 'lightSourceChipType',
            name: value?.name,
            refCode: value?.code,
          },
          fetchPolicy: 'network-only',
        })
        .then(() => {
          this.toastr.success('Created');
          this.master('lightSourceChipType');
          this.isLoading = false;
          this.closeModel();
        })
        .catch((error: any) => {
          this.toastr.error(error.message);
          this.isLoading = false;
        });
    }
  }

  deleteMaster(id: any): void {
    this.graphqlService.client
      .mutate({
        mutation: DELETE_MASTER,
        variables: {
          typeName: 'lightSourceChipType',
          id: id,
        },
        fetchPolicy: 'network-only',
      })
      .then(() => {
        this.toastr.success('Deleted');
        this.master('lightSourceChipType');
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.toastr.error(error.message);
        this.isLoading = false;
      });
  }
}
