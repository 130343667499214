import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
import { GraphqlService } from '../graphql.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RESET } from '../helper/queries';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent {
  loginForm: FormGroup;
  loginSubmit: boolean;
  isLoading: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private router: Router,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    });
  }

  resetLogin(): void {
    localStorage.clear();
    if (this.loginForm.valid) {
      this.isLoading = true;
      const value = this.loginForm.value;
      this.graphqlService.client
        .mutate({
          mutation: RESET,
          variables: { email: value?.email },
          fetchPolicy:"network-only"
        })
        .then((result: any) => {
          this.toastr.success('Password Reset Mail Send Successfully');
          this.router.navigate(['/login']);
        })
        .catch((error: any) => {
          this.isLoading = false
          this.toastr.error('Something Went Wrong... Try Again');
          console.error(error);
        });
    }
  }
}
